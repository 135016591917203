import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _41c45f1c = () => interopDefault(import('../pages/wrapper.vue' /* webpackChunkName: "wrapper" */))
const _25f5be00 = () => interopDefault(import('../pages/base-firmware.vue' /* webpackChunkName: "base-firmware" */))
const _9b8cc324 = () => interopDefault(import('../pages/compare.vue' /* webpackChunkName: "compare" */))
const _ea1bd202 = () => interopDefault(import('../pages/defaultContent.vue' /* webpackChunkName: "connectivity" */))
const _0934532a = () => interopDefault(import('../pages/teltonika-gps.com-landing.vue' /* webpackChunkName: "teltonika-gps.com-landing" */))
const _1b7d4af1 = () => interopDefault(import('../pages/productsTelematics/index.vue' /* webpackChunkName: "productsTelematics" */))
const _73ff5b1d = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "news" */))
const _2673e8af = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "search" */))
const _928d5fe4 = () => interopDefault(import('../pages/dynamic-newsletter.vue' /* webpackChunkName: "dynamic-newsletter" */))
const _10463ea2 = () => interopDefault(import('../pages/media-archive.vue' /* webpackChunkName: "media-archive" */))
const _fd754a6e = () => interopDefault(import('../pages/use-cases/index.vue' /* webpackChunkName: "use-cases" */))
const _cd863b84 = () => interopDefault(import('../pages/careers.vue' /* webpackChunkName: "careers" */))
const _43c793cc = () => interopDefault(import('../pages/contacts.vue' /* webpackChunkName: "contacts" */))
const _17157922 = () => interopDefault(import('../pages/mission-vision-values.vue' /* webpackChunkName: "mission-vision-values" */))
const _89ee1394 = () => interopDefault(import('../pages/policiesCertificates/index.vue' /* webpackChunkName: "policiesCertificates" */))
const _09273272 = () => interopDefault(import('../pages/video/index.vue' /* webpackChunkName: "video" */))
const _2e232d93 = () => interopDefault(import('../pages/webinars/index.vue' /* webpackChunkName: "webinars" */))
const _471ad3d4 = () => interopDefault(import('../pages/eol-products.vue' /* webpackChunkName: "eol-products" */))
const _5c755c58 = () => interopDefault(import('../pages/support.vue' /* webpackChunkName: "support" */))
const _a08b690c = () => interopDefault(import('../pages/product-support.vue' /* webpackChunkName: "product-support" */))
const _f4e2d386 = () => interopDefault(import('../pages/warranty-and-repair.vue' /* webpackChunkName: "warranty-and-repair" */))
const _19c6a9a9 = () => interopDefault(import('../pages/productsTelematics/_slug.vue' /* webpackChunkName: "productsTelematics" */))
const _8d5b5624 = () => interopDefault(import('../pages/policiesCertificates/_slug.vue' /* webpackChunkName: "policiesCertificates" */))
const _0c947502 = () => interopDefault(import('../pages/video/_slug.vue' /* webpackChunkName: "video" */))
const _2c6c8c4b = () => interopDefault(import('../pages/webinars/_slug.vue' /* webpackChunkName: "webinars" */))
const _7f8eb981 = () => interopDefault(import('../pages/use-cases/_slug.vue' /* webpackChunkName: "use-cases" */))
const _7248b9d5 = () => interopDefault(import('../pages/news/_slug.vue' /* webpackChunkName: "news" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fabout-us","ru":"\u002Fabout-us","lt":"\u002Fapie-mus","es":"\u002Fabout-us","pt":"\u002Fabout-us"},"page_id":14},
    name: "about-us-14___en"
  }, {
    path: "/base-firmware",
    component: _25f5be00,
    meta: {"paths":{"en":"\u002Fbase-firmware","ru":"\u002Fbase-firmware","lt":"\u002Fbase-mikroprogramine-iranga","es":"\u002Fbase-firmware","pt":"\u002Fbase-firmware"},"page_id":195},
    name: "base-firmware-195___en"
  }, {
    path: "/compare",
    component: _9b8cc324,
    meta: {"paths":{"en":"\u002Fcompare","ru":"\u002Fcompare","lt":"\u002Fcompare","es":"\u002Fcompare","pt":"\u002Fcompare"},"page_id":165},
    name: "compare-165___en"
  }, {
    path: "/connectivity",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Fconnectivity","ru":"\u002Fconnectivity","lt":"\u002Fconnectivity","es":"\u002Fconnectivity","pt":"\u002Fconnectivity"},"page_id":166},
    name: "connectivity-166___en"
  }, {
    path: "/es",
    component: _0934532a,
    meta: {"paths":{"en":"\u002F","ru":"\u002F","lt":"\u002F","es":"\u002F","pt":"\u002F"},"page_id":2},
    name: "teltonika-gps.com-landing-2___es"
  }, {
    path: "/events",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fevents","ru":"\u002Fevents","lt":"\u002Fevents","es":"\u002Fevents","pt":"\u002Fevents"},"page_id":208},
    name: "events-208___en"
  }, {
    path: "/fotaweb",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Ffotaweb","ru":"\u002Ffotaweb","lt":"\u002Ffotaweb","es":"\u002Ffotaweb","pt":"\u002Ffotaweb"},"page_id":167},
    name: "fotaweb-167___en"
  }, {
    path: "/industries",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Findustries","ru":"\u002Findustries","lt":"\u002Findustrija","es":"\u002Findustries","pt":"\u002Findustries"},"page_id":59},
    name: "industries-59___en"
  }, {
    path: "/lt",
    component: _0934532a,
    meta: {"paths":{"en":"\u002F","ru":"\u002F","lt":"\u002F","es":"\u002F","pt":"\u002F"},"page_id":2},
    name: "teltonika-gps.com-landing-2___lt"
  }, {
    path: "/news",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fnews","ru":"\u002Fnews","lt":"\u002Fnaujienos-1","es":"\u002Fnews","pt":"\u002Fnews"},"page_id":7},
    name: "news-7___en"
  }, {
    path: "/newsroom",
    component: _73ff5b1d,
    meta: {"paths":{"en":"\u002Fnewsroom","ru":"\u002Fnewsroom","lt":"\u002Fnaujienos","es":"\u002Fnewsroom","pt":"\u002Fnewsroom"},"page_id":8},
    name: "newsroom-8___en"
  }, {
    path: "/pt",
    component: _0934532a,
    meta: {"paths":{"en":"\u002F","ru":"\u002F","lt":"\u002F","es":"\u002F","pt":"\u002F"},"page_id":2},
    name: "teltonika-gps.com-landing-2___pt"
  }, {
    path: "/resources",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fresources","ru":"\u002Fresources","lt":"\u002Fresursai","es":"\u002Fresources","pt":"\u002Fresources"},"page_id":28},
    name: "resources-28___en"
  }, {
    path: "/ru",
    component: _0934532a,
    meta: {"paths":{"en":"\u002F","ru":"\u002F","lt":"\u002F","es":"\u002F","pt":"\u002F"},"page_id":2},
    name: "teltonika-gps.com-landing-2___ru"
  }, {
    path: "/search",
    component: _2673e8af,
    meta: {"paths":{"en":"\u002Fsearch","ru":"\u002Fsearch","lt":"\u002Fpaieska","es":"\u002Fsearch","pt":"\u002Fsearch"},"page_id":45},
    name: "search-45___en"
  }, {
    path: "/subscribe",
    component: _928d5fe4,
    meta: {"paths":{"en":"\u002Fsubscribe","ru":"\u002Fsubscribe","lt":"\u002Fprenumeruok","es":"\u002Fsubscribe","pt":"\u002Fsubscribe"},"page_id":158},
    name: "subscribe-158___en"
  }, {
    path: "/support",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fsupport","ru":"\u002Fsupport","lt":"\u002Faptarnavimas","es":"\u002Fsupport","pt":"\u002Fsupport"},"page_id":76},
    name: "support-76___en"
  }, {
    path: "/support-video-tutorials",
    component: _10463ea2,
    meta: {"paths":{"en":"\u002Fsupport-video-tutorials","ru":"\u002Fsupport-video-tutorials","lt":"\u002Fsupport-video-tutorials","es":"\u002Fsupport-video-tutorials","pt":"\u002Fsupport-video-tutorials"},"page_id":170},
    name: "support-video-tutorials-170___en"
  }, {
    path: "/translations",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Ftranslations"}},
    name: "defaultContent___en"
  }, {
    path: "/use-cases",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases","ru":"\u002Fuse-cases","lt":"\u002Fpritaikymo-sritys","es":"\u002Fuse-cases","pt":"\u002Fuse-cases"},"page_id":176},
    name: "use-cases-176___en"
  }, {
    path: "/webtacho",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Fwebtacho","ru":"\u002Fwebtacho","lt":"\u002Fwebtacho","es":"\u002Fwebtacho","pt":"\u002Fwebtacho"},"page_id":168},
    name: "webtacho-168___en"
  }, {
    path: "/about-us/career",
    component: _cd863b84,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fcareer","ru":"\u002Fabout-us\u002Fcareer","lt":"\u002Fapie-mus\u002Fkarjera","es":"\u002Fabout-us\u002Fcareer","pt":"\u002Fabout-us\u002Fcareer"},"page_id":21},
    name: "about-us-career-21___en"
  }, {
    path: "/about-us/contacts",
    component: _43c793cc,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fcontacts","ru":"\u002Fabout-us\u002Fcontacts","lt":"\u002Fapie-mus\u002Fkontaktai","es":"\u002Fabout-us\u002Fcontacts","pt":"\u002Fabout-us\u002Fcontacts"},"page_id":19},
    name: "about-us-contacts-19___en"
  }, {
    path: "/about-us/mission-vision-values",
    component: _17157922,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fmission-vision-values","ru":"\u002Fabout-us\u002Fmission-vision-values","lt":"\u002Fapie-mus\u002Fmisija-vizija-ir-vertybes","es":"\u002Fabout-us\u002Fmission-vision-values","pt":"\u002Fabout-us\u002Fmission-vision-values"},"page_id":16},
    name: "about-us-mission-vision-values-16___en"
  }, {
    path: "/about-us/policies-certificates",
    component: _89ee1394,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates","ru":"\u002Fabout-us\u002Fpolicies-certificates","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai","es":"\u002Fabout-us\u002Fpolicies-certificates","pt":"\u002Fabout-us\u002Fpolicies-certificates"},"page_id":18},
    name: "about-us-policies-certificates-18___en"
  }, {
    path: "/es/about-us",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fabout-us","ru":"\u002Fabout-us","lt":"\u002Fapie-mus","es":"\u002Fabout-us","pt":"\u002Fabout-us"},"page_id":14},
    name: "about-us-14___es"
  }, {
    path: "/es/base-firmware",
    component: _25f5be00,
    meta: {"paths":{"en":"\u002Fbase-firmware","ru":"\u002Fbase-firmware","lt":"\u002Fbase-mikroprogramine-iranga","es":"\u002Fbase-firmware","pt":"\u002Fbase-firmware"},"page_id":195},
    name: "base-firmware-195___es"
  }, {
    path: "/es/compare",
    component: _9b8cc324,
    meta: {"paths":{"en":"\u002Fcompare","ru":"\u002Fcompare","lt":"\u002Fcompare","es":"\u002Fcompare","pt":"\u002Fcompare"},"page_id":165},
    name: "compare-165___es"
  }, {
    path: "/es/connectivity",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Fconnectivity","ru":"\u002Fconnectivity","lt":"\u002Fconnectivity","es":"\u002Fconnectivity","pt":"\u002Fconnectivity"},"page_id":166},
    name: "connectivity-166___es"
  }, {
    path: "/es/events",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fevents","ru":"\u002Fevents","lt":"\u002Fevents","es":"\u002Fevents","pt":"\u002Fevents"},"page_id":208},
    name: "events-208___es"
  }, {
    path: "/es/fotaweb",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Ffotaweb","ru":"\u002Ffotaweb","lt":"\u002Ffotaweb","es":"\u002Ffotaweb","pt":"\u002Ffotaweb"},"page_id":167},
    name: "fotaweb-167___es"
  }, {
    path: "/es/industries",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Findustries","ru":"\u002Findustries","lt":"\u002Findustrija","es":"\u002Findustries","pt":"\u002Findustries"},"page_id":59},
    name: "industries-59___es"
  }, {
    path: "/es/news",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fnews","ru":"\u002Fnews","lt":"\u002Fnaujienos-1","es":"\u002Fnews","pt":"\u002Fnews"},"page_id":7},
    name: "news-7___es"
  }, {
    path: "/es/newsroom",
    component: _73ff5b1d,
    meta: {"paths":{"en":"\u002Fnewsroom","ru":"\u002Fnewsroom","lt":"\u002Fnaujienos","es":"\u002Fnewsroom","pt":"\u002Fnewsroom"},"page_id":8},
    name: "newsroom-8___es"
  }, {
    path: "/es/resources",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fresources","ru":"\u002Fresources","lt":"\u002Fresursai","es":"\u002Fresources","pt":"\u002Fresources"},"page_id":28},
    name: "resources-28___es"
  }, {
    path: "/es/search",
    component: _2673e8af,
    meta: {"paths":{"en":"\u002Fsearch","ru":"\u002Fsearch","lt":"\u002Fpaieska","es":"\u002Fsearch","pt":"\u002Fsearch"},"page_id":45},
    name: "search-45___es"
  }, {
    path: "/es/subscribe",
    component: _928d5fe4,
    meta: {"paths":{"en":"\u002Fsubscribe","ru":"\u002Fsubscribe","lt":"\u002Fprenumeruok","es":"\u002Fsubscribe","pt":"\u002Fsubscribe"},"page_id":158},
    name: "subscribe-158___es"
  }, {
    path: "/es/support",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fsupport","ru":"\u002Fsupport","lt":"\u002Faptarnavimas","es":"\u002Fsupport","pt":"\u002Fsupport"},"page_id":76},
    name: "support-76___es"
  }, {
    path: "/es/support-video-tutorials",
    component: _10463ea2,
    meta: {"paths":{"en":"\u002Fsupport-video-tutorials","ru":"\u002Fsupport-video-tutorials","lt":"\u002Fsupport-video-tutorials","es":"\u002Fsupport-video-tutorials","pt":"\u002Fsupport-video-tutorials"},"page_id":170},
    name: "support-video-tutorials-170___es"
  }, {
    path: "/es/translations",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Ftranslations"}},
    name: "defaultContent___es"
  }, {
    path: "/es/use-cases",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases","ru":"\u002Fuse-cases","lt":"\u002Fpritaikymo-sritys","es":"\u002Fuse-cases","pt":"\u002Fuse-cases"},"page_id":176},
    name: "use-cases-176___es"
  }, {
    path: "/es/webtacho",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Fwebtacho","ru":"\u002Fwebtacho","lt":"\u002Fwebtacho","es":"\u002Fwebtacho","pt":"\u002Fwebtacho"},"page_id":168},
    name: "webtacho-168___es"
  }, {
    path: "/lt/apie-mus",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fabout-us","ru":"\u002Fabout-us","lt":"\u002Fapie-mus","es":"\u002Fabout-us","pt":"\u002Fabout-us"},"page_id":14},
    name: "about-us-14___lt"
  }, {
    path: "/lt/base-mikroprogramine-iranga",
    component: _25f5be00,
    meta: {"paths":{"en":"\u002Fbase-firmware","ru":"\u002Fbase-firmware","lt":"\u002Fbase-mikroprogramine-iranga","es":"\u002Fbase-firmware","pt":"\u002Fbase-firmware"},"page_id":195},
    name: "base-firmware-195___lt"
  }, {
    path: "/lt/compare",
    component: _9b8cc324,
    meta: {"paths":{"en":"\u002Fcompare","ru":"\u002Fcompare","lt":"\u002Fcompare","es":"\u002Fcompare","pt":"\u002Fcompare"},"page_id":165},
    name: "compare-165___lt"
  }, {
    path: "/lt/connectivity",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Fconnectivity","ru":"\u002Fconnectivity","lt":"\u002Fconnectivity","es":"\u002Fconnectivity","pt":"\u002Fconnectivity"},"page_id":166},
    name: "connectivity-166___lt"
  }, {
    path: "/lt/events",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fevents","ru":"\u002Fevents","lt":"\u002Fevents","es":"\u002Fevents","pt":"\u002Fevents"},"page_id":208},
    name: "events-208___lt"
  }, {
    path: "/lt/fotaweb",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Ffotaweb","ru":"\u002Ffotaweb","lt":"\u002Ffotaweb","es":"\u002Ffotaweb","pt":"\u002Ffotaweb"},"page_id":167},
    name: "fotaweb-167___lt"
  }, {
    path: "/lt/industrija",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Findustries","ru":"\u002Findustries","lt":"\u002Findustrija","es":"\u002Findustries","pt":"\u002Findustries"},"page_id":59},
    name: "industries-59___lt"
  }, {
    path: "/lt/naujienos-1",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fnews","ru":"\u002Fnews","lt":"\u002Fnaujienos-1","es":"\u002Fnews","pt":"\u002Fnews"},"page_id":7},
    name: "news-7___lt"
  }, {
    path: "/lt/naujienos",
    component: _73ff5b1d,
    meta: {"paths":{"en":"\u002Fnewsroom","ru":"\u002Fnewsroom","lt":"\u002Fnaujienos","es":"\u002Fnewsroom","pt":"\u002Fnewsroom"},"page_id":8},
    name: "newsroom-8___lt"
  }, {
    path: "/lt/resursai",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fresources","ru":"\u002Fresources","lt":"\u002Fresursai","es":"\u002Fresources","pt":"\u002Fresources"},"page_id":28},
    name: "resources-28___lt"
  }, {
    path: "/lt/paieska",
    component: _2673e8af,
    meta: {"paths":{"en":"\u002Fsearch","ru":"\u002Fsearch","lt":"\u002Fpaieska","es":"\u002Fsearch","pt":"\u002Fsearch"},"page_id":45},
    name: "search-45___lt"
  }, {
    path: "/lt/prenumeruok",
    component: _928d5fe4,
    meta: {"paths":{"en":"\u002Fsubscribe","ru":"\u002Fsubscribe","lt":"\u002Fprenumeruok","es":"\u002Fsubscribe","pt":"\u002Fsubscribe"},"page_id":158},
    name: "subscribe-158___lt"
  }, {
    path: "/lt/aptarnavimas",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fsupport","ru":"\u002Fsupport","lt":"\u002Faptarnavimas","es":"\u002Fsupport","pt":"\u002Fsupport"},"page_id":76},
    name: "support-76___lt"
  }, {
    path: "/lt/support-video-tutorials",
    component: _10463ea2,
    meta: {"paths":{"en":"\u002Fsupport-video-tutorials","ru":"\u002Fsupport-video-tutorials","lt":"\u002Fsupport-video-tutorials","es":"\u002Fsupport-video-tutorials","pt":"\u002Fsupport-video-tutorials"},"page_id":170},
    name: "support-video-tutorials-170___lt"
  }, {
    path: "/lt/translations",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Ftranslations"}},
    name: "defaultContent___lt"
  }, {
    path: "/lt/pritaikymo-sritys",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases","ru":"\u002Fuse-cases","lt":"\u002Fpritaikymo-sritys","es":"\u002Fuse-cases","pt":"\u002Fuse-cases"},"page_id":176},
    name: "use-cases-176___lt"
  }, {
    path: "/lt/webtacho",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Fwebtacho","ru":"\u002Fwebtacho","lt":"\u002Fwebtacho","es":"\u002Fwebtacho","pt":"\u002Fwebtacho"},"page_id":168},
    name: "webtacho-168___lt"
  }, {
    path: "/products/accessories",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories","ru":"\u002Fproducts\u002Faccessories","lt":"\u002Fproduktai\u002Fpriedai","es":"\u002Fproducts\u002Faccessories","pt":"\u002Fproducts\u002Faccessories"},"page_id":139},
    name: "products-accessories-139___en"
  }, {
    path: "/products/solutions",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Fsolutions","ru":"\u002Fproducts\u002Fsolutions","lt":"\u002Fproduktai\u002Fsprendimai","es":"\u002Fproducts\u002Fsolutions","pt":"\u002Fproducts\u002Fsolutions"},"page_id":137},
    name: "products-solutions-137___en"
  }, {
    path: "/products/trackers",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers","ru":"\u002Fproducts\u002Ftrackers","lt":"\u002Fproduktai\u002Fsekimo-iranga","es":"\u002Fproducts\u002Ftrackers","pt":"\u002Fproducts\u002Ftrackers"},"page_id":135},
    name: "products-trackers-135___en"
  }, {
    path: "/pt/about-us",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fabout-us","ru":"\u002Fabout-us","lt":"\u002Fapie-mus","es":"\u002Fabout-us","pt":"\u002Fabout-us"},"page_id":14},
    name: "about-us-14___pt"
  }, {
    path: "/pt/base-firmware",
    component: _25f5be00,
    meta: {"paths":{"en":"\u002Fbase-firmware","ru":"\u002Fbase-firmware","lt":"\u002Fbase-mikroprogramine-iranga","es":"\u002Fbase-firmware","pt":"\u002Fbase-firmware"},"page_id":195},
    name: "base-firmware-195___pt"
  }, {
    path: "/pt/compare",
    component: _9b8cc324,
    meta: {"paths":{"en":"\u002Fcompare","ru":"\u002Fcompare","lt":"\u002Fcompare","es":"\u002Fcompare","pt":"\u002Fcompare"},"page_id":165},
    name: "compare-165___pt"
  }, {
    path: "/pt/connectivity",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Fconnectivity","ru":"\u002Fconnectivity","lt":"\u002Fconnectivity","es":"\u002Fconnectivity","pt":"\u002Fconnectivity"},"page_id":166},
    name: "connectivity-166___pt"
  }, {
    path: "/pt/events",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fevents","ru":"\u002Fevents","lt":"\u002Fevents","es":"\u002Fevents","pt":"\u002Fevents"},"page_id":208},
    name: "events-208___pt"
  }, {
    path: "/pt/fotaweb",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Ffotaweb","ru":"\u002Ffotaweb","lt":"\u002Ffotaweb","es":"\u002Ffotaweb","pt":"\u002Ffotaweb"},"page_id":167},
    name: "fotaweb-167___pt"
  }, {
    path: "/pt/industries",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Findustries","ru":"\u002Findustries","lt":"\u002Findustrija","es":"\u002Findustries","pt":"\u002Findustries"},"page_id":59},
    name: "industries-59___pt"
  }, {
    path: "/pt/news",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fnews","ru":"\u002Fnews","lt":"\u002Fnaujienos-1","es":"\u002Fnews","pt":"\u002Fnews"},"page_id":7},
    name: "news-7___pt"
  }, {
    path: "/pt/newsroom",
    component: _73ff5b1d,
    meta: {"paths":{"en":"\u002Fnewsroom","ru":"\u002Fnewsroom","lt":"\u002Fnaujienos","es":"\u002Fnewsroom","pt":"\u002Fnewsroom"},"page_id":8},
    name: "newsroom-8___pt"
  }, {
    path: "/pt/resources",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fresources","ru":"\u002Fresources","lt":"\u002Fresursai","es":"\u002Fresources","pt":"\u002Fresources"},"page_id":28},
    name: "resources-28___pt"
  }, {
    path: "/pt/search",
    component: _2673e8af,
    meta: {"paths":{"en":"\u002Fsearch","ru":"\u002Fsearch","lt":"\u002Fpaieska","es":"\u002Fsearch","pt":"\u002Fsearch"},"page_id":45},
    name: "search-45___pt"
  }, {
    path: "/pt/subscribe",
    component: _928d5fe4,
    meta: {"paths":{"en":"\u002Fsubscribe","ru":"\u002Fsubscribe","lt":"\u002Fprenumeruok","es":"\u002Fsubscribe","pt":"\u002Fsubscribe"},"page_id":158},
    name: "subscribe-158___pt"
  }, {
    path: "/pt/support",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fsupport","ru":"\u002Fsupport","lt":"\u002Faptarnavimas","es":"\u002Fsupport","pt":"\u002Fsupport"},"page_id":76},
    name: "support-76___pt"
  }, {
    path: "/pt/support-video-tutorials",
    component: _10463ea2,
    meta: {"paths":{"en":"\u002Fsupport-video-tutorials","ru":"\u002Fsupport-video-tutorials","lt":"\u002Fsupport-video-tutorials","es":"\u002Fsupport-video-tutorials","pt":"\u002Fsupport-video-tutorials"},"page_id":170},
    name: "support-video-tutorials-170___pt"
  }, {
    path: "/pt/translations",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Ftranslations"}},
    name: "defaultContent___pt"
  }, {
    path: "/pt/use-cases",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases","ru":"\u002Fuse-cases","lt":"\u002Fpritaikymo-sritys","es":"\u002Fuse-cases","pt":"\u002Fuse-cases"},"page_id":176},
    name: "use-cases-176___pt"
  }, {
    path: "/pt/webtacho",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Fwebtacho","ru":"\u002Fwebtacho","lt":"\u002Fwebtacho","es":"\u002Fwebtacho","pt":"\u002Fwebtacho"},"page_id":168},
    name: "webtacho-168___pt"
  }, {
    path: "/resources/videos",
    component: _09273272,
    meta: {"paths":{"en":"\u002Fresources\u002Fvideos","ru":"\u002Fresources\u002Fvideos","lt":"\u002Fresursai\u002Fvaizdo-irasai","es":"\u002Fresources\u002Fvideos","pt":"\u002Fresources\u002Fvideos"},"page_id":155},
    name: "resources-videos-155___en"
  }, {
    path: "/resources/webinars",
    component: _2e232d93,
    meta: {"paths":{"en":"\u002Fresources\u002Fwebinars","ru":"\u002Fresources\u002Fwebinars","lt":"\u002Fresursai\u002Fwebinarai","es":"\u002Fresources\u002Fwebinars","pt":"\u002Fresources\u002Fwebinars"},"page_id":100},
    name: "resources-webinars-100___en"
  }, {
    path: "/ru/about-us",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fabout-us","ru":"\u002Fabout-us","lt":"\u002Fapie-mus","es":"\u002Fabout-us","pt":"\u002Fabout-us"},"page_id":14},
    name: "about-us-14___ru"
  }, {
    path: "/ru/base-firmware",
    component: _25f5be00,
    meta: {"paths":{"en":"\u002Fbase-firmware","ru":"\u002Fbase-firmware","lt":"\u002Fbase-mikroprogramine-iranga","es":"\u002Fbase-firmware","pt":"\u002Fbase-firmware"},"page_id":195},
    name: "base-firmware-195___ru"
  }, {
    path: "/ru/compare",
    component: _9b8cc324,
    meta: {"paths":{"en":"\u002Fcompare","ru":"\u002Fcompare","lt":"\u002Fcompare","es":"\u002Fcompare","pt":"\u002Fcompare"},"page_id":165},
    name: "compare-165___ru"
  }, {
    path: "/ru/connectivity",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Fconnectivity","ru":"\u002Fconnectivity","lt":"\u002Fconnectivity","es":"\u002Fconnectivity","pt":"\u002Fconnectivity"},"page_id":166},
    name: "connectivity-166___ru"
  }, {
    path: "/ru/events",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fevents","ru":"\u002Fevents","lt":"\u002Fevents","es":"\u002Fevents","pt":"\u002Fevents"},"page_id":208},
    name: "events-208___ru"
  }, {
    path: "/ru/fotaweb",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Ffotaweb","ru":"\u002Ffotaweb","lt":"\u002Ffotaweb","es":"\u002Ffotaweb","pt":"\u002Ffotaweb"},"page_id":167},
    name: "fotaweb-167___ru"
  }, {
    path: "/ru/industries",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Findustries","ru":"\u002Findustries","lt":"\u002Findustrija","es":"\u002Findustries","pt":"\u002Findustries"},"page_id":59},
    name: "industries-59___ru"
  }, {
    path: "/ru/news",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fnews","ru":"\u002Fnews","lt":"\u002Fnaujienos-1","es":"\u002Fnews","pt":"\u002Fnews"},"page_id":7},
    name: "news-7___ru"
  }, {
    path: "/ru/newsroom",
    component: _73ff5b1d,
    meta: {"paths":{"en":"\u002Fnewsroom","ru":"\u002Fnewsroom","lt":"\u002Fnaujienos","es":"\u002Fnewsroom","pt":"\u002Fnewsroom"},"page_id":8},
    name: "newsroom-8___ru"
  }, {
    path: "/ru/resources",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fresources","ru":"\u002Fresources","lt":"\u002Fresursai","es":"\u002Fresources","pt":"\u002Fresources"},"page_id":28},
    name: "resources-28___ru"
  }, {
    path: "/ru/search",
    component: _2673e8af,
    meta: {"paths":{"en":"\u002Fsearch","ru":"\u002Fsearch","lt":"\u002Fpaieska","es":"\u002Fsearch","pt":"\u002Fsearch"},"page_id":45},
    name: "search-45___ru"
  }, {
    path: "/ru/subscribe",
    component: _928d5fe4,
    meta: {"paths":{"en":"\u002Fsubscribe","ru":"\u002Fsubscribe","lt":"\u002Fprenumeruok","es":"\u002Fsubscribe","pt":"\u002Fsubscribe"},"page_id":158},
    name: "subscribe-158___ru"
  }, {
    path: "/ru/support",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fsupport","ru":"\u002Fsupport","lt":"\u002Faptarnavimas","es":"\u002Fsupport","pt":"\u002Fsupport"},"page_id":76},
    name: "support-76___ru"
  }, {
    path: "/ru/support-video-tutorials",
    component: _10463ea2,
    meta: {"paths":{"en":"\u002Fsupport-video-tutorials","ru":"\u002Fsupport-video-tutorials","lt":"\u002Fsupport-video-tutorials","es":"\u002Fsupport-video-tutorials","pt":"\u002Fsupport-video-tutorials"},"page_id":170},
    name: "support-video-tutorials-170___ru"
  }, {
    path: "/ru/translations",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Ftranslations"}},
    name: "defaultContent___ru"
  }, {
    path: "/ru/use-cases",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases","ru":"\u002Fuse-cases","lt":"\u002Fpritaikymo-sritys","es":"\u002Fuse-cases","pt":"\u002Fuse-cases"},"page_id":176},
    name: "use-cases-176___ru"
  }, {
    path: "/ru/webtacho",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Fwebtacho","ru":"\u002Fwebtacho","lt":"\u002Fwebtacho","es":"\u002Fwebtacho","pt":"\u002Fwebtacho"},"page_id":168},
    name: "webtacho-168___ru"
  }, {
    path: "/support/eol-products",
    component: _471ad3d4,
    meta: {"paths":{"en":"\u002Fsupport\u002Feol-products","ru":"\u002Fsupport\u002Feol-products","lt":"\u002Faptarnavimas\u002Fproduktu-gyvavimo-ciklo-pabaiga","es":"\u002Fsupport\u002Feol-products","pt":"\u002Fsupport\u002Feol-products"},"page_id":181},
    name: "support-eol-products-181___en"
  }, {
    path: "/support/product-support",
    component: _5c755c58,
    meta: {"paths":{"en":"\u002Fsupport\u002Fproduct-support","ru":"\u002Fsupport\u002Fpodderzka-produktov","lt":"\u002Faptarnavimas\u002Fgaminiu-palaikymas","es":"\u002Fsupport\u002Fsoporte-de-productos","pt":"\u002Fsupport\u002Fsuporte-tecnico"},"page_id":160},
    name: "support-product-support-160___en"
  }, {
    path: "/support/product-support-1",
    component: _a08b690c,
    meta: {"paths":{"en":"\u002Fsupport\u002Fproduct-support-1","ru":"\u002Fsupport\u002Fproduct-support-1","lt":"\u002Fsupport\u002Fproduct-support-1","es":"\u002Fsupport\u002Fproduct-support-1","pt":"\u002Fsupport\u002Fproduct-support-1"},"page_id":61},
    name: "support-product-support-1-61___en"
  }, {
    path: "/support/warranty-repair",
    component: _f4e2d386,
    meta: {"paths":{"en":"\u002Fsupport\u002Fwarranty-repair","ru":"\u002Fsupport\u002Fgarantiia-i-remont","lt":"\u002Faptarnavimas\u002Fgarantija-ir-remontas","es":"\u002Fsupport\u002Fgarantia-y-reparaciones","pt":"\u002Fsupport\u002Fwarranty-repair"},"page_id":75},
    name: "support-warranty-repair-75___en"
  }, {
    path: "/use-cases/agriculture",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fagriculture","ru":"\u002Fuse-cases\u002Fagriculture","lt":"\u002Fpritaikymo-sritys\u002Fzemes-ukis","es":"\u002Fuse-cases\u002Fagriculture","pt":"\u002Fuse-cases\u002Fagriculture"},"page_id":146},
    name: "use-cases-agriculture-146___en"
  }, {
    path: "/use-cases/assets-and-workforce",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fassets-and-workforce","ru":"\u002Fuse-cases\u002Fassets-and-workforce","lt":"\u002Fpritaikymo-sritys\u002Fobjektai-ir-personalas-1","es":"\u002Fuse-cases\u002Fassets-and-workforce","pt":"\u002Fuse-cases\u002Fassets-and-workforce"},"page_id":193},
    name: "use-cases-assets-and-workforce-193___en"
  }, {
    path: "/use-cases/car-sharing-rental",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fcar-sharing-rental","ru":"\u002Fuse-cases\u002Fcar-sharing-rental","lt":"\u002Fpritaikymo-sritys\u002Fautomobiliu-nuoma-ir-dalijimasis","es":"\u002Fuse-cases\u002Fcar-sharing-rental","pt":"\u002Fuse-cases\u002Fcar-sharing-rental"},"page_id":147},
    name: "use-cases-car-sharing-rental-147___en"
  }, {
    path: "/use-cases/construction-mining",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fconstruction-mining","ru":"\u002Fuse-cases\u002Fconstruction-mining","lt":"\u002Fpritaikymo-sritys\u002Fstatyba-ir-kalnakasyba","es":"\u002Fuse-cases\u002Fconstruction-mining","pt":"\u002Fuse-cases\u002Fconstruction-mining"},"page_id":149},
    name: "use-cases-construction-mining-149___en"
  }, {
    path: "/use-cases/driver-safety",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fdriver-safety","ru":"\u002Fuse-cases\u002Fdriver-safety","lt":"\u002Fpritaikymo-sritys\u002Fvairuotoju-sauga","es":"\u002Fuse-cases\u002Fdriver-safety","pt":"\u002Fuse-cases\u002Fdriver-safety"},"page_id":194},
    name: "use-cases-driver-safety-194___en"
  }, {
    path: "/use-cases/emobility-management",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Femobility-management","ru":"\u002Fuse-cases\u002Femobility-management","lt":"\u002Fpritaikymo-sritys\u002Femobilumas","es":"\u002Fuse-cases\u002Femobility-management","pt":"\u002Fuse-cases\u002Femobility-management"},"page_id":190},
    name: "use-cases-emobility-management-190___en"
  }, {
    path: "/use-cases/logistics",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Flogistics","ru":"\u002Fuse-cases\u002Flogistics","lt":"\u002Fpritaikymo-sritys\u002Flogistika","es":"\u002Fuse-cases\u002Flogistics","pt":"\u002Fuse-cases\u002Flogistics"},"page_id":150},
    name: "use-cases-logistics-150___en"
  }, {
    path: "/use-cases/telematics",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Ftelematics","ru":"\u002Fuse-cases\u002Ftelematics","lt":"\u002Fpritaikymo-sritys\u002Ftelematika","es":"\u002Fuse-cases\u002Ftelematics","pt":"\u002Fuse-cases\u002Ftelematics"},"page_id":144},
    name: "use-cases-telematics-144___en"
  }, {
    path: "/use-cases/utility-services",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Futility-services","ru":"\u002Fuse-cases\u002Futility-services","lt":"\u002Fpritaikymo-sritys\u002Fkomunalines-paslaugos","es":"\u002Fuse-cases\u002Futility-services","pt":"\u002Fuse-cases\u002Futility-services"},"page_id":145},
    name: "use-cases-utility-services-145___en"
  }, {
    path: "/es/about-us/career",
    component: _cd863b84,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fcareer","ru":"\u002Fabout-us\u002Fcareer","lt":"\u002Fapie-mus\u002Fkarjera","es":"\u002Fabout-us\u002Fcareer","pt":"\u002Fabout-us\u002Fcareer"},"page_id":21},
    name: "about-us-career-21___es"
  }, {
    path: "/es/about-us/contacts",
    component: _43c793cc,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fcontacts","ru":"\u002Fabout-us\u002Fcontacts","lt":"\u002Fapie-mus\u002Fkontaktai","es":"\u002Fabout-us\u002Fcontacts","pt":"\u002Fabout-us\u002Fcontacts"},"page_id":19},
    name: "about-us-contacts-19___es"
  }, {
    path: "/es/about-us/mission-vision-values",
    component: _17157922,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fmission-vision-values","ru":"\u002Fabout-us\u002Fmission-vision-values","lt":"\u002Fapie-mus\u002Fmisija-vizija-ir-vertybes","es":"\u002Fabout-us\u002Fmission-vision-values","pt":"\u002Fabout-us\u002Fmission-vision-values"},"page_id":16},
    name: "about-us-mission-vision-values-16___es"
  }, {
    path: "/es/about-us/policies-certificates",
    component: _89ee1394,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates","ru":"\u002Fabout-us\u002Fpolicies-certificates","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai","es":"\u002Fabout-us\u002Fpolicies-certificates","pt":"\u002Fabout-us\u002Fpolicies-certificates"},"page_id":18},
    name: "about-us-policies-certificates-18___es"
  }, {
    path: "/es/products/accessories",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories","ru":"\u002Fproducts\u002Faccessories","lt":"\u002Fproduktai\u002Fpriedai","es":"\u002Fproducts\u002Faccessories","pt":"\u002Fproducts\u002Faccessories"},"page_id":139},
    name: "products-accessories-139___es"
  }, {
    path: "/es/products/solutions",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Fsolutions","ru":"\u002Fproducts\u002Fsolutions","lt":"\u002Fproduktai\u002Fsprendimai","es":"\u002Fproducts\u002Fsolutions","pt":"\u002Fproducts\u002Fsolutions"},"page_id":137},
    name: "products-solutions-137___es"
  }, {
    path: "/es/products/trackers",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers","ru":"\u002Fproducts\u002Ftrackers","lt":"\u002Fproduktai\u002Fsekimo-iranga","es":"\u002Fproducts\u002Ftrackers","pt":"\u002Fproducts\u002Ftrackers"},"page_id":135},
    name: "products-trackers-135___es"
  }, {
    path: "/es/resources/videos",
    component: _09273272,
    meta: {"paths":{"en":"\u002Fresources\u002Fvideos","ru":"\u002Fresources\u002Fvideos","lt":"\u002Fresursai\u002Fvaizdo-irasai","es":"\u002Fresources\u002Fvideos","pt":"\u002Fresources\u002Fvideos"},"page_id":155},
    name: "resources-videos-155___es"
  }, {
    path: "/es/resources/webinars",
    component: _2e232d93,
    meta: {"paths":{"en":"\u002Fresources\u002Fwebinars","ru":"\u002Fresources\u002Fwebinars","lt":"\u002Fresursai\u002Fwebinarai","es":"\u002Fresources\u002Fwebinars","pt":"\u002Fresources\u002Fwebinars"},"page_id":100},
    name: "resources-webinars-100___es"
  }, {
    path: "/es/support/eol-products",
    component: _471ad3d4,
    meta: {"paths":{"en":"\u002Fsupport\u002Feol-products","ru":"\u002Fsupport\u002Feol-products","lt":"\u002Faptarnavimas\u002Fproduktu-gyvavimo-ciklo-pabaiga","es":"\u002Fsupport\u002Feol-products","pt":"\u002Fsupport\u002Feol-products"},"page_id":181},
    name: "support-eol-products-181___es"
  }, {
    path: "/es/support/soporte-de-productos",
    component: _5c755c58,
    meta: {"paths":{"en":"\u002Fsupport\u002Fproduct-support","ru":"\u002Fsupport\u002Fpodderzka-produktov","lt":"\u002Faptarnavimas\u002Fgaminiu-palaikymas","es":"\u002Fsupport\u002Fsoporte-de-productos","pt":"\u002Fsupport\u002Fsuporte-tecnico"},"page_id":160},
    name: "support-product-support-160___es"
  }, {
    path: "/es/support/product-support-1",
    component: _a08b690c,
    meta: {"paths":{"en":"\u002Fsupport\u002Fproduct-support-1","ru":"\u002Fsupport\u002Fproduct-support-1","lt":"\u002Fsupport\u002Fproduct-support-1","es":"\u002Fsupport\u002Fproduct-support-1","pt":"\u002Fsupport\u002Fproduct-support-1"},"page_id":61},
    name: "support-product-support-1-61___es"
  }, {
    path: "/es/support/garantia-y-reparaciones",
    component: _f4e2d386,
    meta: {"paths":{"en":"\u002Fsupport\u002Fwarranty-repair","ru":"\u002Fsupport\u002Fgarantiia-i-remont","lt":"\u002Faptarnavimas\u002Fgarantija-ir-remontas","es":"\u002Fsupport\u002Fgarantia-y-reparaciones","pt":"\u002Fsupport\u002Fwarranty-repair"},"page_id":75},
    name: "support-warranty-repair-75___es"
  }, {
    path: "/es/use-cases/agriculture",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fagriculture","ru":"\u002Fuse-cases\u002Fagriculture","lt":"\u002Fpritaikymo-sritys\u002Fzemes-ukis","es":"\u002Fuse-cases\u002Fagriculture","pt":"\u002Fuse-cases\u002Fagriculture"},"page_id":146},
    name: "use-cases-agriculture-146___es"
  }, {
    path: "/es/use-cases/assets-and-workforce",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fassets-and-workforce","ru":"\u002Fuse-cases\u002Fassets-and-workforce","lt":"\u002Fpritaikymo-sritys\u002Fobjektai-ir-personalas-1","es":"\u002Fuse-cases\u002Fassets-and-workforce","pt":"\u002Fuse-cases\u002Fassets-and-workforce"},"page_id":193},
    name: "use-cases-assets-and-workforce-193___es"
  }, {
    path: "/es/use-cases/car-sharing-rental",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fcar-sharing-rental","ru":"\u002Fuse-cases\u002Fcar-sharing-rental","lt":"\u002Fpritaikymo-sritys\u002Fautomobiliu-nuoma-ir-dalijimasis","es":"\u002Fuse-cases\u002Fcar-sharing-rental","pt":"\u002Fuse-cases\u002Fcar-sharing-rental"},"page_id":147},
    name: "use-cases-car-sharing-rental-147___es"
  }, {
    path: "/es/use-cases/construction-mining",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fconstruction-mining","ru":"\u002Fuse-cases\u002Fconstruction-mining","lt":"\u002Fpritaikymo-sritys\u002Fstatyba-ir-kalnakasyba","es":"\u002Fuse-cases\u002Fconstruction-mining","pt":"\u002Fuse-cases\u002Fconstruction-mining"},"page_id":149},
    name: "use-cases-construction-mining-149___es"
  }, {
    path: "/es/use-cases/driver-safety",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fdriver-safety","ru":"\u002Fuse-cases\u002Fdriver-safety","lt":"\u002Fpritaikymo-sritys\u002Fvairuotoju-sauga","es":"\u002Fuse-cases\u002Fdriver-safety","pt":"\u002Fuse-cases\u002Fdriver-safety"},"page_id":194},
    name: "use-cases-driver-safety-194___es"
  }, {
    path: "/es/use-cases/emobility-management",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Femobility-management","ru":"\u002Fuse-cases\u002Femobility-management","lt":"\u002Fpritaikymo-sritys\u002Femobilumas","es":"\u002Fuse-cases\u002Femobility-management","pt":"\u002Fuse-cases\u002Femobility-management"},"page_id":190},
    name: "use-cases-emobility-management-190___es"
  }, {
    path: "/es/use-cases/logistics",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Flogistics","ru":"\u002Fuse-cases\u002Flogistics","lt":"\u002Fpritaikymo-sritys\u002Flogistika","es":"\u002Fuse-cases\u002Flogistics","pt":"\u002Fuse-cases\u002Flogistics"},"page_id":150},
    name: "use-cases-logistics-150___es"
  }, {
    path: "/es/use-cases/telematics",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Ftelematics","ru":"\u002Fuse-cases\u002Ftelematics","lt":"\u002Fpritaikymo-sritys\u002Ftelematika","es":"\u002Fuse-cases\u002Ftelematics","pt":"\u002Fuse-cases\u002Ftelematics"},"page_id":144},
    name: "use-cases-telematics-144___es"
  }, {
    path: "/es/use-cases/utility-services",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Futility-services","ru":"\u002Fuse-cases\u002Futility-services","lt":"\u002Fpritaikymo-sritys\u002Fkomunalines-paslaugos","es":"\u002Fuse-cases\u002Futility-services","pt":"\u002Fuse-cases\u002Futility-services"},"page_id":145},
    name: "use-cases-utility-services-145___es"
  }, {
    path: "/lt/apie-mus/karjera",
    component: _cd863b84,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fcareer","ru":"\u002Fabout-us\u002Fcareer","lt":"\u002Fapie-mus\u002Fkarjera","es":"\u002Fabout-us\u002Fcareer","pt":"\u002Fabout-us\u002Fcareer"},"page_id":21},
    name: "about-us-career-21___lt"
  }, {
    path: "/lt/apie-mus/kontaktai",
    component: _43c793cc,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fcontacts","ru":"\u002Fabout-us\u002Fcontacts","lt":"\u002Fapie-mus\u002Fkontaktai","es":"\u002Fabout-us\u002Fcontacts","pt":"\u002Fabout-us\u002Fcontacts"},"page_id":19},
    name: "about-us-contacts-19___lt"
  }, {
    path: "/lt/apie-mus/misija-vizija-ir-vertybes",
    component: _17157922,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fmission-vision-values","ru":"\u002Fabout-us\u002Fmission-vision-values","lt":"\u002Fapie-mus\u002Fmisija-vizija-ir-vertybes","es":"\u002Fabout-us\u002Fmission-vision-values","pt":"\u002Fabout-us\u002Fmission-vision-values"},"page_id":16},
    name: "about-us-mission-vision-values-16___lt"
  }, {
    path: "/lt/apie-mus/politika-ir-sertifikatai",
    component: _89ee1394,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates","ru":"\u002Fabout-us\u002Fpolicies-certificates","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai","es":"\u002Fabout-us\u002Fpolicies-certificates","pt":"\u002Fabout-us\u002Fpolicies-certificates"},"page_id":18},
    name: "about-us-policies-certificates-18___lt"
  }, {
    path: "/lt/produktai/priedai",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories","ru":"\u002Fproducts\u002Faccessories","lt":"\u002Fproduktai\u002Fpriedai","es":"\u002Fproducts\u002Faccessories","pt":"\u002Fproducts\u002Faccessories"},"page_id":139},
    name: "products-accessories-139___lt"
  }, {
    path: "/lt/produktai/sprendimai",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Fsolutions","ru":"\u002Fproducts\u002Fsolutions","lt":"\u002Fproduktai\u002Fsprendimai","es":"\u002Fproducts\u002Fsolutions","pt":"\u002Fproducts\u002Fsolutions"},"page_id":137},
    name: "products-solutions-137___lt"
  }, {
    path: "/lt/produktai/sekimo-iranga",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers","ru":"\u002Fproducts\u002Ftrackers","lt":"\u002Fproduktai\u002Fsekimo-iranga","es":"\u002Fproducts\u002Ftrackers","pt":"\u002Fproducts\u002Ftrackers"},"page_id":135},
    name: "products-trackers-135___lt"
  }, {
    path: "/lt/resursai/vaizdo-irasai",
    component: _09273272,
    meta: {"paths":{"en":"\u002Fresources\u002Fvideos","ru":"\u002Fresources\u002Fvideos","lt":"\u002Fresursai\u002Fvaizdo-irasai","es":"\u002Fresources\u002Fvideos","pt":"\u002Fresources\u002Fvideos"},"page_id":155},
    name: "resources-videos-155___lt"
  }, {
    path: "/lt/resursai/webinarai",
    component: _2e232d93,
    meta: {"paths":{"en":"\u002Fresources\u002Fwebinars","ru":"\u002Fresources\u002Fwebinars","lt":"\u002Fresursai\u002Fwebinarai","es":"\u002Fresources\u002Fwebinars","pt":"\u002Fresources\u002Fwebinars"},"page_id":100},
    name: "resources-webinars-100___lt"
  }, {
    path: "/lt/aptarnavimas/produktu-gyvavimo-ciklo-pabaiga",
    component: _471ad3d4,
    meta: {"paths":{"en":"\u002Fsupport\u002Feol-products","ru":"\u002Fsupport\u002Feol-products","lt":"\u002Faptarnavimas\u002Fproduktu-gyvavimo-ciklo-pabaiga","es":"\u002Fsupport\u002Feol-products","pt":"\u002Fsupport\u002Feol-products"},"page_id":181},
    name: "support-eol-products-181___lt"
  }, {
    path: "/lt/aptarnavimas/gaminiu-palaikymas",
    component: _5c755c58,
    meta: {"paths":{"en":"\u002Fsupport\u002Fproduct-support","ru":"\u002Fsupport\u002Fpodderzka-produktov","lt":"\u002Faptarnavimas\u002Fgaminiu-palaikymas","es":"\u002Fsupport\u002Fsoporte-de-productos","pt":"\u002Fsupport\u002Fsuporte-tecnico"},"page_id":160},
    name: "support-product-support-160___lt"
  }, {
    path: "/lt/support/product-support-1",
    component: _a08b690c,
    meta: {"paths":{"en":"\u002Fsupport\u002Fproduct-support-1","ru":"\u002Fsupport\u002Fproduct-support-1","lt":"\u002Fsupport\u002Fproduct-support-1","es":"\u002Fsupport\u002Fproduct-support-1","pt":"\u002Fsupport\u002Fproduct-support-1"},"page_id":61},
    name: "support-product-support-1-61___lt"
  }, {
    path: "/lt/aptarnavimas/garantija-ir-remontas",
    component: _f4e2d386,
    meta: {"paths":{"en":"\u002Fsupport\u002Fwarranty-repair","ru":"\u002Fsupport\u002Fgarantiia-i-remont","lt":"\u002Faptarnavimas\u002Fgarantija-ir-remontas","es":"\u002Fsupport\u002Fgarantia-y-reparaciones","pt":"\u002Fsupport\u002Fwarranty-repair"},"page_id":75},
    name: "support-warranty-repair-75___lt"
  }, {
    path: "/lt/pritaikymo-sritys/zemes-ukis",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fagriculture","ru":"\u002Fuse-cases\u002Fagriculture","lt":"\u002Fpritaikymo-sritys\u002Fzemes-ukis","es":"\u002Fuse-cases\u002Fagriculture","pt":"\u002Fuse-cases\u002Fagriculture"},"page_id":146},
    name: "use-cases-agriculture-146___lt"
  }, {
    path: "/lt/pritaikymo-sritys/objektai-ir-personalas-1",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fassets-and-workforce","ru":"\u002Fuse-cases\u002Fassets-and-workforce","lt":"\u002Fpritaikymo-sritys\u002Fobjektai-ir-personalas-1","es":"\u002Fuse-cases\u002Fassets-and-workforce","pt":"\u002Fuse-cases\u002Fassets-and-workforce"},"page_id":193},
    name: "use-cases-assets-and-workforce-193___lt"
  }, {
    path: "/lt/pritaikymo-sritys/automobiliu-nuoma-ir-dalijimasis",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fcar-sharing-rental","ru":"\u002Fuse-cases\u002Fcar-sharing-rental","lt":"\u002Fpritaikymo-sritys\u002Fautomobiliu-nuoma-ir-dalijimasis","es":"\u002Fuse-cases\u002Fcar-sharing-rental","pt":"\u002Fuse-cases\u002Fcar-sharing-rental"},"page_id":147},
    name: "use-cases-car-sharing-rental-147___lt"
  }, {
    path: "/lt/pritaikymo-sritys/statyba-ir-kalnakasyba",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fconstruction-mining","ru":"\u002Fuse-cases\u002Fconstruction-mining","lt":"\u002Fpritaikymo-sritys\u002Fstatyba-ir-kalnakasyba","es":"\u002Fuse-cases\u002Fconstruction-mining","pt":"\u002Fuse-cases\u002Fconstruction-mining"},"page_id":149},
    name: "use-cases-construction-mining-149___lt"
  }, {
    path: "/lt/pritaikymo-sritys/vairuotoju-sauga",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fdriver-safety","ru":"\u002Fuse-cases\u002Fdriver-safety","lt":"\u002Fpritaikymo-sritys\u002Fvairuotoju-sauga","es":"\u002Fuse-cases\u002Fdriver-safety","pt":"\u002Fuse-cases\u002Fdriver-safety"},"page_id":194},
    name: "use-cases-driver-safety-194___lt"
  }, {
    path: "/lt/pritaikymo-sritys/emobilumas",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Femobility-management","ru":"\u002Fuse-cases\u002Femobility-management","lt":"\u002Fpritaikymo-sritys\u002Femobilumas","es":"\u002Fuse-cases\u002Femobility-management","pt":"\u002Fuse-cases\u002Femobility-management"},"page_id":190},
    name: "use-cases-emobility-management-190___lt"
  }, {
    path: "/lt/pritaikymo-sritys/logistika",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Flogistics","ru":"\u002Fuse-cases\u002Flogistics","lt":"\u002Fpritaikymo-sritys\u002Flogistika","es":"\u002Fuse-cases\u002Flogistics","pt":"\u002Fuse-cases\u002Flogistics"},"page_id":150},
    name: "use-cases-logistics-150___lt"
  }, {
    path: "/lt/pritaikymo-sritys/telematika",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Ftelematics","ru":"\u002Fuse-cases\u002Ftelematics","lt":"\u002Fpritaikymo-sritys\u002Ftelematika","es":"\u002Fuse-cases\u002Ftelematics","pt":"\u002Fuse-cases\u002Ftelematics"},"page_id":144},
    name: "use-cases-telematics-144___lt"
  }, {
    path: "/lt/pritaikymo-sritys/komunalines-paslaugos",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Futility-services","ru":"\u002Fuse-cases\u002Futility-services","lt":"\u002Fpritaikymo-sritys\u002Fkomunalines-paslaugos","es":"\u002Fuse-cases\u002Futility-services","pt":"\u002Fuse-cases\u002Futility-services"},"page_id":145},
    name: "use-cases-utility-services-145___lt"
  }, {
    path: "/products/accessories/can-adapters",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fcan-adapters","ru":"\u002Fproducts\u002Faccessories\u002Fcan-adapters","lt":"\u002Fproduktai\u002Fpriedai\u002Fcan-adapteriai","es":"\u002Fproducts\u002Faccessories\u002Fcan-adapters","pt":"\u002Fproducts\u002Faccessories\u002Fcan-adapters"},"page_id":178},
    name: "products-accessories-can-adapters-178___en"
  }, {
    path: "/products/accessories/data-cables",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fdata-cables","ru":"\u002Fproducts\u002Faccessories\u002Fdata-cables","lt":"\u002Fproduktai\u002Fpriedai\u002Fduomenu-perdavimo-kabeliai","es":"\u002Fproducts\u002Faccessories\u002Fdata-cables","pt":"\u002Fproducts\u002Faccessories\u002Fdata-cables"},"page_id":173},
    name: "products-accessories-data-cables-173___en"
  }, {
    path: "/products/accessories/other",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fother","ru":"\u002Fproducts\u002Faccessories\u002Fother","lt":"\u002Fproduktai\u002Fpriedai\u002Fkiti-priedai","es":"\u002Fproducts\u002Faccessories\u002Fother","pt":"\u002Fproducts\u002Faccessories\u002Fother"},"page_id":177},
    name: "products-accessories-other-177___en"
  }, {
    path: "/products/accessories/power-cables",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fpower-cables","ru":"\u002Fproducts\u002Faccessories\u002Fpower-cables","lt":"\u002Fproduktai\u002Fpriedai\u002Fmaitinimo-kabeliai","es":"\u002Fproducts\u002Faccessories\u002Fpower-cables","pt":"\u002Fproducts\u002Faccessories\u002Fpower-cables"},"page_id":175},
    name: "products-accessories-power-cables-175___en"
  }, {
    path: "/products/accessories/readers-holders",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Freaders-holders","ru":"\u002Fproducts\u002Faccessories\u002Freaders-holders","lt":"\u002Fproduktai\u002Fpriedai\u002Fskaitytuvai-ir-laikikliai","es":"\u002Fproducts\u002Faccessories\u002Freaders-holders","pt":"\u002Fproducts\u002Faccessories\u002Freaders-holders"},"page_id":174},
    name: "products-accessories-readers-holders-174___en"
  }, {
    path: "/products/accessories/sensors-beacons",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fsensors-beacons","ru":"\u002Fproducts\u002Faccessories\u002Fsensors-beacons","lt":"\u002Fproduktai\u002Fpriedai\u002Fjutikliai-ir-signalu-siustuvai","es":"\u002Fproducts\u002Faccessories\u002Fsensors-beacons","pt":"\u002Fproducts\u002Faccessories\u002Fsensors-beacons"},"page_id":172},
    name: "products-accessories-sensors-beacons-172___en"
  }, {
    path: "/products/accessories/video-telematics",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fvideo-telematics","ru":"\u002Fproducts\u002Faccessories\u002Fvideo-telematics","lt":"\u002Fproduktai\u002Fpriedai\u002Fvideo-telematika","es":"\u002Fproducts\u002Faccessories\u002Fvideo-telematics","pt":"\u002Fproducts\u002Faccessories\u002Fvideo-telematics"},"page_id":141},
    name: "products-accessories-video-telematics-141___en"
  }, {
    path: "/products/trackers/advanced",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fadvanced","ru":"\u002Fproducts\u002Ftrackers\u002Fadvanced","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fadvanced","es":"\u002Fproducts\u002Ftrackers\u002Fadvanced","pt":"\u002Fproducts\u002Ftrackers\u002Fadvanced"},"page_id":140},
    name: "products-trackers-advanced-140___en"
  }, {
    path: "/products/trackers/assets-workforce",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fassets-workforce","ru":"\u002Fproducts\u002Ftrackers\u002Fassets-workforce","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fobjektai-ir-personalas","es":"\u002Fproducts\u002Ftrackers\u002Fassets-workforce","pt":"\u002Fproducts\u002Ftrackers\u002Fassets-workforce"},"page_id":169},
    name: "products-trackers-assets-workforce-169___en"
  }, {
    path: "/products/trackers/basic",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fbasic","ru":"\u002Fproducts\u002Ftrackers\u002Fbasic","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fbasic","es":"\u002Fproducts\u002Ftrackers\u002Fbasic","pt":"\u002Fproducts\u002Ftrackers\u002Fbasic"},"page_id":138},
    name: "products-trackers-basic-138___en"
  }, {
    path: "/products/trackers/can-data",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fcan-data","ru":"\u002Fproducts\u002Ftrackers\u002Fcan-data","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fcan-data","es":"\u002Fproducts\u002Ftrackers\u002Fcan-data","pt":"\u002Fproducts\u002Ftrackers\u002Fcan-data"},"page_id":186},
    name: "products-trackers-can-data-186___en"
  }, {
    path: "/products/trackers/can-obd-data",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fcan-obd-data","ru":"\u002Fproducts\u002Ftrackers\u002Fcan-obd-data","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fcan-obd-duomenys","es":"\u002Fproducts\u002Ftrackers\u002Fcan-obd-data","pt":"\u002Fproducts\u002Ftrackers\u002Fcan-obd-data"},"page_id":143},
    name: "products-trackers-can-obd-data-143___en"
  }, {
    path: "/products/trackers/e-mobility",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fe-mobility","ru":"\u002Fproducts\u002Ftrackers\u002Fe-mobility","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fe-mobility","es":"\u002Fproducts\u002Ftrackers\u002Fe-mobility","pt":"\u002Fproducts\u002Ftrackers\u002Fe-mobility"},"page_id":188},
    name: "products-trackers-e-mobility-188___en"
  }, {
    path: "/products/trackers/fast-easy",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Ffast-easy","ru":"\u002Fproducts\u002Ftrackers\u002Ffast-easy","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Ffast-easy","es":"\u002Fproducts\u002Ftrackers\u002Ffast-easy","pt":"\u002Fproducts\u002Ftrackers\u002Ffast-easy"},"page_id":136},
    name: "products-trackers-fast-easy-136___en"
  }, {
    path: "/products/trackers/obd-data",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fobd-data","ru":"\u002Fproducts\u002Ftrackers\u002Fobd-data","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fobd-data","es":"\u002Fproducts\u002Ftrackers\u002Fobd-data","pt":"\u002Fproducts\u002Ftrackers\u002Fobd-data"},"page_id":187},
    name: "products-trackers-obd-data-187___en"
  }, {
    path: "/products/trackers/professional",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fprofessional","ru":"\u002Fproducts\u002Ftrackers\u002Fprofessional","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fprofessional","es":"\u002Fproducts\u002Ftrackers\u002Fprofessional","pt":"\u002Fproducts\u002Ftrackers\u002Fprofessional"},"page_id":142},
    name: "products-trackers-professional-142___en"
  }, {
    path: "/pt/about-us/career",
    component: _cd863b84,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fcareer","ru":"\u002Fabout-us\u002Fcareer","lt":"\u002Fapie-mus\u002Fkarjera","es":"\u002Fabout-us\u002Fcareer","pt":"\u002Fabout-us\u002Fcareer"},"page_id":21},
    name: "about-us-career-21___pt"
  }, {
    path: "/pt/about-us/contacts",
    component: _43c793cc,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fcontacts","ru":"\u002Fabout-us\u002Fcontacts","lt":"\u002Fapie-mus\u002Fkontaktai","es":"\u002Fabout-us\u002Fcontacts","pt":"\u002Fabout-us\u002Fcontacts"},"page_id":19},
    name: "about-us-contacts-19___pt"
  }, {
    path: "/pt/about-us/mission-vision-values",
    component: _17157922,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fmission-vision-values","ru":"\u002Fabout-us\u002Fmission-vision-values","lt":"\u002Fapie-mus\u002Fmisija-vizija-ir-vertybes","es":"\u002Fabout-us\u002Fmission-vision-values","pt":"\u002Fabout-us\u002Fmission-vision-values"},"page_id":16},
    name: "about-us-mission-vision-values-16___pt"
  }, {
    path: "/pt/about-us/policies-certificates",
    component: _89ee1394,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates","ru":"\u002Fabout-us\u002Fpolicies-certificates","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai","es":"\u002Fabout-us\u002Fpolicies-certificates","pt":"\u002Fabout-us\u002Fpolicies-certificates"},"page_id":18},
    name: "about-us-policies-certificates-18___pt"
  }, {
    path: "/pt/products/accessories",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories","ru":"\u002Fproducts\u002Faccessories","lt":"\u002Fproduktai\u002Fpriedai","es":"\u002Fproducts\u002Faccessories","pt":"\u002Fproducts\u002Faccessories"},"page_id":139},
    name: "products-accessories-139___pt"
  }, {
    path: "/pt/products/solutions",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Fsolutions","ru":"\u002Fproducts\u002Fsolutions","lt":"\u002Fproduktai\u002Fsprendimai","es":"\u002Fproducts\u002Fsolutions","pt":"\u002Fproducts\u002Fsolutions"},"page_id":137},
    name: "products-solutions-137___pt"
  }, {
    path: "/pt/products/trackers",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers","ru":"\u002Fproducts\u002Ftrackers","lt":"\u002Fproduktai\u002Fsekimo-iranga","es":"\u002Fproducts\u002Ftrackers","pt":"\u002Fproducts\u002Ftrackers"},"page_id":135},
    name: "products-trackers-135___pt"
  }, {
    path: "/pt/resources/videos",
    component: _09273272,
    meta: {"paths":{"en":"\u002Fresources\u002Fvideos","ru":"\u002Fresources\u002Fvideos","lt":"\u002Fresursai\u002Fvaizdo-irasai","es":"\u002Fresources\u002Fvideos","pt":"\u002Fresources\u002Fvideos"},"page_id":155},
    name: "resources-videos-155___pt"
  }, {
    path: "/pt/resources/webinars",
    component: _2e232d93,
    meta: {"paths":{"en":"\u002Fresources\u002Fwebinars","ru":"\u002Fresources\u002Fwebinars","lt":"\u002Fresursai\u002Fwebinarai","es":"\u002Fresources\u002Fwebinars","pt":"\u002Fresources\u002Fwebinars"},"page_id":100},
    name: "resources-webinars-100___pt"
  }, {
    path: "/pt/support/eol-products",
    component: _471ad3d4,
    meta: {"paths":{"en":"\u002Fsupport\u002Feol-products","ru":"\u002Fsupport\u002Feol-products","lt":"\u002Faptarnavimas\u002Fproduktu-gyvavimo-ciklo-pabaiga","es":"\u002Fsupport\u002Feol-products","pt":"\u002Fsupport\u002Feol-products"},"page_id":181},
    name: "support-eol-products-181___pt"
  }, {
    path: "/pt/support/suporte-tecnico",
    component: _5c755c58,
    meta: {"paths":{"en":"\u002Fsupport\u002Fproduct-support","ru":"\u002Fsupport\u002Fpodderzka-produktov","lt":"\u002Faptarnavimas\u002Fgaminiu-palaikymas","es":"\u002Fsupport\u002Fsoporte-de-productos","pt":"\u002Fsupport\u002Fsuporte-tecnico"},"page_id":160},
    name: "support-product-support-160___pt"
  }, {
    path: "/pt/support/product-support-1",
    component: _a08b690c,
    meta: {"paths":{"en":"\u002Fsupport\u002Fproduct-support-1","ru":"\u002Fsupport\u002Fproduct-support-1","lt":"\u002Fsupport\u002Fproduct-support-1","es":"\u002Fsupport\u002Fproduct-support-1","pt":"\u002Fsupport\u002Fproduct-support-1"},"page_id":61},
    name: "support-product-support-1-61___pt"
  }, {
    path: "/pt/support/warranty-repair",
    component: _f4e2d386,
    meta: {"paths":{"en":"\u002Fsupport\u002Fwarranty-repair","ru":"\u002Fsupport\u002Fgarantiia-i-remont","lt":"\u002Faptarnavimas\u002Fgarantija-ir-remontas","es":"\u002Fsupport\u002Fgarantia-y-reparaciones","pt":"\u002Fsupport\u002Fwarranty-repair"},"page_id":75},
    name: "support-warranty-repair-75___pt"
  }, {
    path: "/pt/use-cases/agriculture",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fagriculture","ru":"\u002Fuse-cases\u002Fagriculture","lt":"\u002Fpritaikymo-sritys\u002Fzemes-ukis","es":"\u002Fuse-cases\u002Fagriculture","pt":"\u002Fuse-cases\u002Fagriculture"},"page_id":146},
    name: "use-cases-agriculture-146___pt"
  }, {
    path: "/pt/use-cases/assets-and-workforce",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fassets-and-workforce","ru":"\u002Fuse-cases\u002Fassets-and-workforce","lt":"\u002Fpritaikymo-sritys\u002Fobjektai-ir-personalas-1","es":"\u002Fuse-cases\u002Fassets-and-workforce","pt":"\u002Fuse-cases\u002Fassets-and-workforce"},"page_id":193},
    name: "use-cases-assets-and-workforce-193___pt"
  }, {
    path: "/pt/use-cases/car-sharing-rental",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fcar-sharing-rental","ru":"\u002Fuse-cases\u002Fcar-sharing-rental","lt":"\u002Fpritaikymo-sritys\u002Fautomobiliu-nuoma-ir-dalijimasis","es":"\u002Fuse-cases\u002Fcar-sharing-rental","pt":"\u002Fuse-cases\u002Fcar-sharing-rental"},"page_id":147},
    name: "use-cases-car-sharing-rental-147___pt"
  }, {
    path: "/pt/use-cases/construction-mining",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fconstruction-mining","ru":"\u002Fuse-cases\u002Fconstruction-mining","lt":"\u002Fpritaikymo-sritys\u002Fstatyba-ir-kalnakasyba","es":"\u002Fuse-cases\u002Fconstruction-mining","pt":"\u002Fuse-cases\u002Fconstruction-mining"},"page_id":149},
    name: "use-cases-construction-mining-149___pt"
  }, {
    path: "/pt/use-cases/driver-safety",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fdriver-safety","ru":"\u002Fuse-cases\u002Fdriver-safety","lt":"\u002Fpritaikymo-sritys\u002Fvairuotoju-sauga","es":"\u002Fuse-cases\u002Fdriver-safety","pt":"\u002Fuse-cases\u002Fdriver-safety"},"page_id":194},
    name: "use-cases-driver-safety-194___pt"
  }, {
    path: "/pt/use-cases/emobility-management",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Femobility-management","ru":"\u002Fuse-cases\u002Femobility-management","lt":"\u002Fpritaikymo-sritys\u002Femobilumas","es":"\u002Fuse-cases\u002Femobility-management","pt":"\u002Fuse-cases\u002Femobility-management"},"page_id":190},
    name: "use-cases-emobility-management-190___pt"
  }, {
    path: "/pt/use-cases/logistics",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Flogistics","ru":"\u002Fuse-cases\u002Flogistics","lt":"\u002Fpritaikymo-sritys\u002Flogistika","es":"\u002Fuse-cases\u002Flogistics","pt":"\u002Fuse-cases\u002Flogistics"},"page_id":150},
    name: "use-cases-logistics-150___pt"
  }, {
    path: "/pt/use-cases/telematics",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Ftelematics","ru":"\u002Fuse-cases\u002Ftelematics","lt":"\u002Fpritaikymo-sritys\u002Ftelematika","es":"\u002Fuse-cases\u002Ftelematics","pt":"\u002Fuse-cases\u002Ftelematics"},"page_id":144},
    name: "use-cases-telematics-144___pt"
  }, {
    path: "/pt/use-cases/utility-services",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Futility-services","ru":"\u002Fuse-cases\u002Futility-services","lt":"\u002Fpritaikymo-sritys\u002Fkomunalines-paslaugos","es":"\u002Fuse-cases\u002Futility-services","pt":"\u002Fuse-cases\u002Futility-services"},"page_id":145},
    name: "use-cases-utility-services-145___pt"
  }, {
    path: "/ru/about-us/career",
    component: _cd863b84,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fcareer","ru":"\u002Fabout-us\u002Fcareer","lt":"\u002Fapie-mus\u002Fkarjera","es":"\u002Fabout-us\u002Fcareer","pt":"\u002Fabout-us\u002Fcareer"},"page_id":21},
    name: "about-us-career-21___ru"
  }, {
    path: "/ru/about-us/contacts",
    component: _43c793cc,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fcontacts","ru":"\u002Fabout-us\u002Fcontacts","lt":"\u002Fapie-mus\u002Fkontaktai","es":"\u002Fabout-us\u002Fcontacts","pt":"\u002Fabout-us\u002Fcontacts"},"page_id":19},
    name: "about-us-contacts-19___ru"
  }, {
    path: "/ru/about-us/mission-vision-values",
    component: _17157922,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fmission-vision-values","ru":"\u002Fabout-us\u002Fmission-vision-values","lt":"\u002Fapie-mus\u002Fmisija-vizija-ir-vertybes","es":"\u002Fabout-us\u002Fmission-vision-values","pt":"\u002Fabout-us\u002Fmission-vision-values"},"page_id":16},
    name: "about-us-mission-vision-values-16___ru"
  }, {
    path: "/ru/about-us/policies-certificates",
    component: _89ee1394,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates","ru":"\u002Fabout-us\u002Fpolicies-certificates","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai","es":"\u002Fabout-us\u002Fpolicies-certificates","pt":"\u002Fabout-us\u002Fpolicies-certificates"},"page_id":18},
    name: "about-us-policies-certificates-18___ru"
  }, {
    path: "/ru/products/accessories",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories","ru":"\u002Fproducts\u002Faccessories","lt":"\u002Fproduktai\u002Fpriedai","es":"\u002Fproducts\u002Faccessories","pt":"\u002Fproducts\u002Faccessories"},"page_id":139},
    name: "products-accessories-139___ru"
  }, {
    path: "/ru/products/solutions",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Fsolutions","ru":"\u002Fproducts\u002Fsolutions","lt":"\u002Fproduktai\u002Fsprendimai","es":"\u002Fproducts\u002Fsolutions","pt":"\u002Fproducts\u002Fsolutions"},"page_id":137},
    name: "products-solutions-137___ru"
  }, {
    path: "/ru/products/trackers",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers","ru":"\u002Fproducts\u002Ftrackers","lt":"\u002Fproduktai\u002Fsekimo-iranga","es":"\u002Fproducts\u002Ftrackers","pt":"\u002Fproducts\u002Ftrackers"},"page_id":135},
    name: "products-trackers-135___ru"
  }, {
    path: "/ru/resources/videos",
    component: _09273272,
    meta: {"paths":{"en":"\u002Fresources\u002Fvideos","ru":"\u002Fresources\u002Fvideos","lt":"\u002Fresursai\u002Fvaizdo-irasai","es":"\u002Fresources\u002Fvideos","pt":"\u002Fresources\u002Fvideos"},"page_id":155},
    name: "resources-videos-155___ru"
  }, {
    path: "/ru/resources/webinars",
    component: _2e232d93,
    meta: {"paths":{"en":"\u002Fresources\u002Fwebinars","ru":"\u002Fresources\u002Fwebinars","lt":"\u002Fresursai\u002Fwebinarai","es":"\u002Fresources\u002Fwebinars","pt":"\u002Fresources\u002Fwebinars"},"page_id":100},
    name: "resources-webinars-100___ru"
  }, {
    path: "/ru/support/eol-products",
    component: _471ad3d4,
    meta: {"paths":{"en":"\u002Fsupport\u002Feol-products","ru":"\u002Fsupport\u002Feol-products","lt":"\u002Faptarnavimas\u002Fproduktu-gyvavimo-ciklo-pabaiga","es":"\u002Fsupport\u002Feol-products","pt":"\u002Fsupport\u002Feol-products"},"page_id":181},
    name: "support-eol-products-181___ru"
  }, {
    path: "/ru/support/podderzka-produktov",
    component: _5c755c58,
    meta: {"paths":{"en":"\u002Fsupport\u002Fproduct-support","ru":"\u002Fsupport\u002Fpodderzka-produktov","lt":"\u002Faptarnavimas\u002Fgaminiu-palaikymas","es":"\u002Fsupport\u002Fsoporte-de-productos","pt":"\u002Fsupport\u002Fsuporte-tecnico"},"page_id":160},
    name: "support-product-support-160___ru"
  }, {
    path: "/ru/support/product-support-1",
    component: _a08b690c,
    meta: {"paths":{"en":"\u002Fsupport\u002Fproduct-support-1","ru":"\u002Fsupport\u002Fproduct-support-1","lt":"\u002Fsupport\u002Fproduct-support-1","es":"\u002Fsupport\u002Fproduct-support-1","pt":"\u002Fsupport\u002Fproduct-support-1"},"page_id":61},
    name: "support-product-support-1-61___ru"
  }, {
    path: "/ru/support/garantiia-i-remont",
    component: _f4e2d386,
    meta: {"paths":{"en":"\u002Fsupport\u002Fwarranty-repair","ru":"\u002Fsupport\u002Fgarantiia-i-remont","lt":"\u002Faptarnavimas\u002Fgarantija-ir-remontas","es":"\u002Fsupport\u002Fgarantia-y-reparaciones","pt":"\u002Fsupport\u002Fwarranty-repair"},"page_id":75},
    name: "support-warranty-repair-75___ru"
  }, {
    path: "/ru/use-cases/agriculture",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fagriculture","ru":"\u002Fuse-cases\u002Fagriculture","lt":"\u002Fpritaikymo-sritys\u002Fzemes-ukis","es":"\u002Fuse-cases\u002Fagriculture","pt":"\u002Fuse-cases\u002Fagriculture"},"page_id":146},
    name: "use-cases-agriculture-146___ru"
  }, {
    path: "/ru/use-cases/assets-and-workforce",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fassets-and-workforce","ru":"\u002Fuse-cases\u002Fassets-and-workforce","lt":"\u002Fpritaikymo-sritys\u002Fobjektai-ir-personalas-1","es":"\u002Fuse-cases\u002Fassets-and-workforce","pt":"\u002Fuse-cases\u002Fassets-and-workforce"},"page_id":193},
    name: "use-cases-assets-and-workforce-193___ru"
  }, {
    path: "/ru/use-cases/car-sharing-rental",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fcar-sharing-rental","ru":"\u002Fuse-cases\u002Fcar-sharing-rental","lt":"\u002Fpritaikymo-sritys\u002Fautomobiliu-nuoma-ir-dalijimasis","es":"\u002Fuse-cases\u002Fcar-sharing-rental","pt":"\u002Fuse-cases\u002Fcar-sharing-rental"},"page_id":147},
    name: "use-cases-car-sharing-rental-147___ru"
  }, {
    path: "/ru/use-cases/construction-mining",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fconstruction-mining","ru":"\u002Fuse-cases\u002Fconstruction-mining","lt":"\u002Fpritaikymo-sritys\u002Fstatyba-ir-kalnakasyba","es":"\u002Fuse-cases\u002Fconstruction-mining","pt":"\u002Fuse-cases\u002Fconstruction-mining"},"page_id":149},
    name: "use-cases-construction-mining-149___ru"
  }, {
    path: "/ru/use-cases/driver-safety",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fdriver-safety","ru":"\u002Fuse-cases\u002Fdriver-safety","lt":"\u002Fpritaikymo-sritys\u002Fvairuotoju-sauga","es":"\u002Fuse-cases\u002Fdriver-safety","pt":"\u002Fuse-cases\u002Fdriver-safety"},"page_id":194},
    name: "use-cases-driver-safety-194___ru"
  }, {
    path: "/ru/use-cases/emobility-management",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Femobility-management","ru":"\u002Fuse-cases\u002Femobility-management","lt":"\u002Fpritaikymo-sritys\u002Femobilumas","es":"\u002Fuse-cases\u002Femobility-management","pt":"\u002Fuse-cases\u002Femobility-management"},"page_id":190},
    name: "use-cases-emobility-management-190___ru"
  }, {
    path: "/ru/use-cases/logistics",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Flogistics","ru":"\u002Fuse-cases\u002Flogistics","lt":"\u002Fpritaikymo-sritys\u002Flogistika","es":"\u002Fuse-cases\u002Flogistics","pt":"\u002Fuse-cases\u002Flogistics"},"page_id":150},
    name: "use-cases-logistics-150___ru"
  }, {
    path: "/ru/use-cases/telematics",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Ftelematics","ru":"\u002Fuse-cases\u002Ftelematics","lt":"\u002Fpritaikymo-sritys\u002Ftelematika","es":"\u002Fuse-cases\u002Ftelematics","pt":"\u002Fuse-cases\u002Ftelematics"},"page_id":144},
    name: "use-cases-telematics-144___ru"
  }, {
    path: "/ru/use-cases/utility-services",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Futility-services","ru":"\u002Fuse-cases\u002Futility-services","lt":"\u002Fpritaikymo-sritys\u002Fkomunalines-paslaugos","es":"\u002Fuse-cases\u002Futility-services","pt":"\u002Fuse-cases\u002Futility-services"},"page_id":145},
    name: "use-cases-utility-services-145___ru"
  }, {
    path: "/es/products/accessories/can-adapters",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fcan-adapters","ru":"\u002Fproducts\u002Faccessories\u002Fcan-adapters","lt":"\u002Fproduktai\u002Fpriedai\u002Fcan-adapteriai","es":"\u002Fproducts\u002Faccessories\u002Fcan-adapters","pt":"\u002Fproducts\u002Faccessories\u002Fcan-adapters"},"page_id":178},
    name: "products-accessories-can-adapters-178___es"
  }, {
    path: "/es/products/accessories/data-cables",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fdata-cables","ru":"\u002Fproducts\u002Faccessories\u002Fdata-cables","lt":"\u002Fproduktai\u002Fpriedai\u002Fduomenu-perdavimo-kabeliai","es":"\u002Fproducts\u002Faccessories\u002Fdata-cables","pt":"\u002Fproducts\u002Faccessories\u002Fdata-cables"},"page_id":173},
    name: "products-accessories-data-cables-173___es"
  }, {
    path: "/es/products/accessories/other",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fother","ru":"\u002Fproducts\u002Faccessories\u002Fother","lt":"\u002Fproduktai\u002Fpriedai\u002Fkiti-priedai","es":"\u002Fproducts\u002Faccessories\u002Fother","pt":"\u002Fproducts\u002Faccessories\u002Fother"},"page_id":177},
    name: "products-accessories-other-177___es"
  }, {
    path: "/es/products/accessories/power-cables",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fpower-cables","ru":"\u002Fproducts\u002Faccessories\u002Fpower-cables","lt":"\u002Fproduktai\u002Fpriedai\u002Fmaitinimo-kabeliai","es":"\u002Fproducts\u002Faccessories\u002Fpower-cables","pt":"\u002Fproducts\u002Faccessories\u002Fpower-cables"},"page_id":175},
    name: "products-accessories-power-cables-175___es"
  }, {
    path: "/es/products/accessories/readers-holders",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Freaders-holders","ru":"\u002Fproducts\u002Faccessories\u002Freaders-holders","lt":"\u002Fproduktai\u002Fpriedai\u002Fskaitytuvai-ir-laikikliai","es":"\u002Fproducts\u002Faccessories\u002Freaders-holders","pt":"\u002Fproducts\u002Faccessories\u002Freaders-holders"},"page_id":174},
    name: "products-accessories-readers-holders-174___es"
  }, {
    path: "/es/products/accessories/sensors-beacons",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fsensors-beacons","ru":"\u002Fproducts\u002Faccessories\u002Fsensors-beacons","lt":"\u002Fproduktai\u002Fpriedai\u002Fjutikliai-ir-signalu-siustuvai","es":"\u002Fproducts\u002Faccessories\u002Fsensors-beacons","pt":"\u002Fproducts\u002Faccessories\u002Fsensors-beacons"},"page_id":172},
    name: "products-accessories-sensors-beacons-172___es"
  }, {
    path: "/es/products/accessories/video-telematics",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fvideo-telematics","ru":"\u002Fproducts\u002Faccessories\u002Fvideo-telematics","lt":"\u002Fproduktai\u002Fpriedai\u002Fvideo-telematika","es":"\u002Fproducts\u002Faccessories\u002Fvideo-telematics","pt":"\u002Fproducts\u002Faccessories\u002Fvideo-telematics"},"page_id":141},
    name: "products-accessories-video-telematics-141___es"
  }, {
    path: "/es/products/trackers/advanced",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fadvanced","ru":"\u002Fproducts\u002Ftrackers\u002Fadvanced","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fadvanced","es":"\u002Fproducts\u002Ftrackers\u002Fadvanced","pt":"\u002Fproducts\u002Ftrackers\u002Fadvanced"},"page_id":140},
    name: "products-trackers-advanced-140___es"
  }, {
    path: "/es/products/trackers/assets-workforce",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fassets-workforce","ru":"\u002Fproducts\u002Ftrackers\u002Fassets-workforce","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fobjektai-ir-personalas","es":"\u002Fproducts\u002Ftrackers\u002Fassets-workforce","pt":"\u002Fproducts\u002Ftrackers\u002Fassets-workforce"},"page_id":169},
    name: "products-trackers-assets-workforce-169___es"
  }, {
    path: "/es/products/trackers/basic",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fbasic","ru":"\u002Fproducts\u002Ftrackers\u002Fbasic","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fbasic","es":"\u002Fproducts\u002Ftrackers\u002Fbasic","pt":"\u002Fproducts\u002Ftrackers\u002Fbasic"},"page_id":138},
    name: "products-trackers-basic-138___es"
  }, {
    path: "/es/products/trackers/can-data",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fcan-data","ru":"\u002Fproducts\u002Ftrackers\u002Fcan-data","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fcan-data","es":"\u002Fproducts\u002Ftrackers\u002Fcan-data","pt":"\u002Fproducts\u002Ftrackers\u002Fcan-data"},"page_id":186},
    name: "products-trackers-can-data-186___es"
  }, {
    path: "/es/products/trackers/can-obd-data",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fcan-obd-data","ru":"\u002Fproducts\u002Ftrackers\u002Fcan-obd-data","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fcan-obd-duomenys","es":"\u002Fproducts\u002Ftrackers\u002Fcan-obd-data","pt":"\u002Fproducts\u002Ftrackers\u002Fcan-obd-data"},"page_id":143},
    name: "products-trackers-can-obd-data-143___es"
  }, {
    path: "/es/products/trackers/e-mobility",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fe-mobility","ru":"\u002Fproducts\u002Ftrackers\u002Fe-mobility","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fe-mobility","es":"\u002Fproducts\u002Ftrackers\u002Fe-mobility","pt":"\u002Fproducts\u002Ftrackers\u002Fe-mobility"},"page_id":188},
    name: "products-trackers-e-mobility-188___es"
  }, {
    path: "/es/products/trackers/fast-easy",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Ffast-easy","ru":"\u002Fproducts\u002Ftrackers\u002Ffast-easy","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Ffast-easy","es":"\u002Fproducts\u002Ftrackers\u002Ffast-easy","pt":"\u002Fproducts\u002Ftrackers\u002Ffast-easy"},"page_id":136},
    name: "products-trackers-fast-easy-136___es"
  }, {
    path: "/es/products/trackers/obd-data",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fobd-data","ru":"\u002Fproducts\u002Ftrackers\u002Fobd-data","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fobd-data","es":"\u002Fproducts\u002Ftrackers\u002Fobd-data","pt":"\u002Fproducts\u002Ftrackers\u002Fobd-data"},"page_id":187},
    name: "products-trackers-obd-data-187___es"
  }, {
    path: "/es/products/trackers/professional",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fprofessional","ru":"\u002Fproducts\u002Ftrackers\u002Fprofessional","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fprofessional","es":"\u002Fproducts\u002Ftrackers\u002Fprofessional","pt":"\u002Fproducts\u002Ftrackers\u002Fprofessional"},"page_id":142},
    name: "products-trackers-professional-142___es"
  }, {
    path: "/lt/produktai/priedai/can-adapteriai",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fcan-adapters","ru":"\u002Fproducts\u002Faccessories\u002Fcan-adapters","lt":"\u002Fproduktai\u002Fpriedai\u002Fcan-adapteriai","es":"\u002Fproducts\u002Faccessories\u002Fcan-adapters","pt":"\u002Fproducts\u002Faccessories\u002Fcan-adapters"},"page_id":178},
    name: "products-accessories-can-adapters-178___lt"
  }, {
    path: "/lt/produktai/priedai/duomenu-perdavimo-kabeliai",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fdata-cables","ru":"\u002Fproducts\u002Faccessories\u002Fdata-cables","lt":"\u002Fproduktai\u002Fpriedai\u002Fduomenu-perdavimo-kabeliai","es":"\u002Fproducts\u002Faccessories\u002Fdata-cables","pt":"\u002Fproducts\u002Faccessories\u002Fdata-cables"},"page_id":173},
    name: "products-accessories-data-cables-173___lt"
  }, {
    path: "/lt/produktai/priedai/kiti-priedai",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fother","ru":"\u002Fproducts\u002Faccessories\u002Fother","lt":"\u002Fproduktai\u002Fpriedai\u002Fkiti-priedai","es":"\u002Fproducts\u002Faccessories\u002Fother","pt":"\u002Fproducts\u002Faccessories\u002Fother"},"page_id":177},
    name: "products-accessories-other-177___lt"
  }, {
    path: "/lt/produktai/priedai/maitinimo-kabeliai",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fpower-cables","ru":"\u002Fproducts\u002Faccessories\u002Fpower-cables","lt":"\u002Fproduktai\u002Fpriedai\u002Fmaitinimo-kabeliai","es":"\u002Fproducts\u002Faccessories\u002Fpower-cables","pt":"\u002Fproducts\u002Faccessories\u002Fpower-cables"},"page_id":175},
    name: "products-accessories-power-cables-175___lt"
  }, {
    path: "/lt/produktai/priedai/skaitytuvai-ir-laikikliai",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Freaders-holders","ru":"\u002Fproducts\u002Faccessories\u002Freaders-holders","lt":"\u002Fproduktai\u002Fpriedai\u002Fskaitytuvai-ir-laikikliai","es":"\u002Fproducts\u002Faccessories\u002Freaders-holders","pt":"\u002Fproducts\u002Faccessories\u002Freaders-holders"},"page_id":174},
    name: "products-accessories-readers-holders-174___lt"
  }, {
    path: "/lt/produktai/priedai/jutikliai-ir-signalu-siustuvai",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fsensors-beacons","ru":"\u002Fproducts\u002Faccessories\u002Fsensors-beacons","lt":"\u002Fproduktai\u002Fpriedai\u002Fjutikliai-ir-signalu-siustuvai","es":"\u002Fproducts\u002Faccessories\u002Fsensors-beacons","pt":"\u002Fproducts\u002Faccessories\u002Fsensors-beacons"},"page_id":172},
    name: "products-accessories-sensors-beacons-172___lt"
  }, {
    path: "/lt/produktai/priedai/video-telematika",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fvideo-telematics","ru":"\u002Fproducts\u002Faccessories\u002Fvideo-telematics","lt":"\u002Fproduktai\u002Fpriedai\u002Fvideo-telematika","es":"\u002Fproducts\u002Faccessories\u002Fvideo-telematics","pt":"\u002Fproducts\u002Faccessories\u002Fvideo-telematics"},"page_id":141},
    name: "products-accessories-video-telematics-141___lt"
  }, {
    path: "/lt/produktai/sekimo-iranga/advanced",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fadvanced","ru":"\u002Fproducts\u002Ftrackers\u002Fadvanced","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fadvanced","es":"\u002Fproducts\u002Ftrackers\u002Fadvanced","pt":"\u002Fproducts\u002Ftrackers\u002Fadvanced"},"page_id":140},
    name: "products-trackers-advanced-140___lt"
  }, {
    path: "/lt/produktai/sekimo-iranga/objektai-ir-personalas",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fassets-workforce","ru":"\u002Fproducts\u002Ftrackers\u002Fassets-workforce","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fobjektai-ir-personalas","es":"\u002Fproducts\u002Ftrackers\u002Fassets-workforce","pt":"\u002Fproducts\u002Ftrackers\u002Fassets-workforce"},"page_id":169},
    name: "products-trackers-assets-workforce-169___lt"
  }, {
    path: "/lt/produktai/sekimo-iranga/basic",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fbasic","ru":"\u002Fproducts\u002Ftrackers\u002Fbasic","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fbasic","es":"\u002Fproducts\u002Ftrackers\u002Fbasic","pt":"\u002Fproducts\u002Ftrackers\u002Fbasic"},"page_id":138},
    name: "products-trackers-basic-138___lt"
  }, {
    path: "/lt/produktai/sekimo-iranga/can-data",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fcan-data","ru":"\u002Fproducts\u002Ftrackers\u002Fcan-data","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fcan-data","es":"\u002Fproducts\u002Ftrackers\u002Fcan-data","pt":"\u002Fproducts\u002Ftrackers\u002Fcan-data"},"page_id":186},
    name: "products-trackers-can-data-186___lt"
  }, {
    path: "/lt/produktai/sekimo-iranga/can-obd-duomenys",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fcan-obd-data","ru":"\u002Fproducts\u002Ftrackers\u002Fcan-obd-data","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fcan-obd-duomenys","es":"\u002Fproducts\u002Ftrackers\u002Fcan-obd-data","pt":"\u002Fproducts\u002Ftrackers\u002Fcan-obd-data"},"page_id":143},
    name: "products-trackers-can-obd-data-143___lt"
  }, {
    path: "/lt/produktai/sekimo-iranga/e-mobility",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fe-mobility","ru":"\u002Fproducts\u002Ftrackers\u002Fe-mobility","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fe-mobility","es":"\u002Fproducts\u002Ftrackers\u002Fe-mobility","pt":"\u002Fproducts\u002Ftrackers\u002Fe-mobility"},"page_id":188},
    name: "products-trackers-e-mobility-188___lt"
  }, {
    path: "/lt/produktai/sekimo-iranga/fast-easy",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Ffast-easy","ru":"\u002Fproducts\u002Ftrackers\u002Ffast-easy","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Ffast-easy","es":"\u002Fproducts\u002Ftrackers\u002Ffast-easy","pt":"\u002Fproducts\u002Ftrackers\u002Ffast-easy"},"page_id":136},
    name: "products-trackers-fast-easy-136___lt"
  }, {
    path: "/lt/produktai/sekimo-iranga/obd-data",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fobd-data","ru":"\u002Fproducts\u002Ftrackers\u002Fobd-data","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fobd-data","es":"\u002Fproducts\u002Ftrackers\u002Fobd-data","pt":"\u002Fproducts\u002Ftrackers\u002Fobd-data"},"page_id":187},
    name: "products-trackers-obd-data-187___lt"
  }, {
    path: "/lt/produktai/sekimo-iranga/professional",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fprofessional","ru":"\u002Fproducts\u002Ftrackers\u002Fprofessional","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fprofessional","es":"\u002Fproducts\u002Ftrackers\u002Fprofessional","pt":"\u002Fproducts\u002Ftrackers\u002Fprofessional"},"page_id":142},
    name: "products-trackers-professional-142___lt"
  }, {
    path: "/pt/products/accessories/can-adapters",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fcan-adapters","ru":"\u002Fproducts\u002Faccessories\u002Fcan-adapters","lt":"\u002Fproduktai\u002Fpriedai\u002Fcan-adapteriai","es":"\u002Fproducts\u002Faccessories\u002Fcan-adapters","pt":"\u002Fproducts\u002Faccessories\u002Fcan-adapters"},"page_id":178},
    name: "products-accessories-can-adapters-178___pt"
  }, {
    path: "/pt/products/accessories/data-cables",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fdata-cables","ru":"\u002Fproducts\u002Faccessories\u002Fdata-cables","lt":"\u002Fproduktai\u002Fpriedai\u002Fduomenu-perdavimo-kabeliai","es":"\u002Fproducts\u002Faccessories\u002Fdata-cables","pt":"\u002Fproducts\u002Faccessories\u002Fdata-cables"},"page_id":173},
    name: "products-accessories-data-cables-173___pt"
  }, {
    path: "/pt/products/accessories/other",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fother","ru":"\u002Fproducts\u002Faccessories\u002Fother","lt":"\u002Fproduktai\u002Fpriedai\u002Fkiti-priedai","es":"\u002Fproducts\u002Faccessories\u002Fother","pt":"\u002Fproducts\u002Faccessories\u002Fother"},"page_id":177},
    name: "products-accessories-other-177___pt"
  }, {
    path: "/pt/products/accessories/power-cables",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fpower-cables","ru":"\u002Fproducts\u002Faccessories\u002Fpower-cables","lt":"\u002Fproduktai\u002Fpriedai\u002Fmaitinimo-kabeliai","es":"\u002Fproducts\u002Faccessories\u002Fpower-cables","pt":"\u002Fproducts\u002Faccessories\u002Fpower-cables"},"page_id":175},
    name: "products-accessories-power-cables-175___pt"
  }, {
    path: "/pt/products/accessories/readers-holders",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Freaders-holders","ru":"\u002Fproducts\u002Faccessories\u002Freaders-holders","lt":"\u002Fproduktai\u002Fpriedai\u002Fskaitytuvai-ir-laikikliai","es":"\u002Fproducts\u002Faccessories\u002Freaders-holders","pt":"\u002Fproducts\u002Faccessories\u002Freaders-holders"},"page_id":174},
    name: "products-accessories-readers-holders-174___pt"
  }, {
    path: "/pt/products/accessories/sensors-beacons",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fsensors-beacons","ru":"\u002Fproducts\u002Faccessories\u002Fsensors-beacons","lt":"\u002Fproduktai\u002Fpriedai\u002Fjutikliai-ir-signalu-siustuvai","es":"\u002Fproducts\u002Faccessories\u002Fsensors-beacons","pt":"\u002Fproducts\u002Faccessories\u002Fsensors-beacons"},"page_id":172},
    name: "products-accessories-sensors-beacons-172___pt"
  }, {
    path: "/pt/products/accessories/video-telematics",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fvideo-telematics","ru":"\u002Fproducts\u002Faccessories\u002Fvideo-telematics","lt":"\u002Fproduktai\u002Fpriedai\u002Fvideo-telematika","es":"\u002Fproducts\u002Faccessories\u002Fvideo-telematics","pt":"\u002Fproducts\u002Faccessories\u002Fvideo-telematics"},"page_id":141},
    name: "products-accessories-video-telematics-141___pt"
  }, {
    path: "/pt/products/trackers/advanced",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fadvanced","ru":"\u002Fproducts\u002Ftrackers\u002Fadvanced","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fadvanced","es":"\u002Fproducts\u002Ftrackers\u002Fadvanced","pt":"\u002Fproducts\u002Ftrackers\u002Fadvanced"},"page_id":140},
    name: "products-trackers-advanced-140___pt"
  }, {
    path: "/pt/products/trackers/assets-workforce",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fassets-workforce","ru":"\u002Fproducts\u002Ftrackers\u002Fassets-workforce","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fobjektai-ir-personalas","es":"\u002Fproducts\u002Ftrackers\u002Fassets-workforce","pt":"\u002Fproducts\u002Ftrackers\u002Fassets-workforce"},"page_id":169},
    name: "products-trackers-assets-workforce-169___pt"
  }, {
    path: "/pt/products/trackers/basic",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fbasic","ru":"\u002Fproducts\u002Ftrackers\u002Fbasic","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fbasic","es":"\u002Fproducts\u002Ftrackers\u002Fbasic","pt":"\u002Fproducts\u002Ftrackers\u002Fbasic"},"page_id":138},
    name: "products-trackers-basic-138___pt"
  }, {
    path: "/pt/products/trackers/can-data",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fcan-data","ru":"\u002Fproducts\u002Ftrackers\u002Fcan-data","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fcan-data","es":"\u002Fproducts\u002Ftrackers\u002Fcan-data","pt":"\u002Fproducts\u002Ftrackers\u002Fcan-data"},"page_id":186},
    name: "products-trackers-can-data-186___pt"
  }, {
    path: "/pt/products/trackers/can-obd-data",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fcan-obd-data","ru":"\u002Fproducts\u002Ftrackers\u002Fcan-obd-data","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fcan-obd-duomenys","es":"\u002Fproducts\u002Ftrackers\u002Fcan-obd-data","pt":"\u002Fproducts\u002Ftrackers\u002Fcan-obd-data"},"page_id":143},
    name: "products-trackers-can-obd-data-143___pt"
  }, {
    path: "/pt/products/trackers/e-mobility",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fe-mobility","ru":"\u002Fproducts\u002Ftrackers\u002Fe-mobility","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fe-mobility","es":"\u002Fproducts\u002Ftrackers\u002Fe-mobility","pt":"\u002Fproducts\u002Ftrackers\u002Fe-mobility"},"page_id":188},
    name: "products-trackers-e-mobility-188___pt"
  }, {
    path: "/pt/products/trackers/fast-easy",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Ffast-easy","ru":"\u002Fproducts\u002Ftrackers\u002Ffast-easy","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Ffast-easy","es":"\u002Fproducts\u002Ftrackers\u002Ffast-easy","pt":"\u002Fproducts\u002Ftrackers\u002Ffast-easy"},"page_id":136},
    name: "products-trackers-fast-easy-136___pt"
  }, {
    path: "/pt/products/trackers/obd-data",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fobd-data","ru":"\u002Fproducts\u002Ftrackers\u002Fobd-data","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fobd-data","es":"\u002Fproducts\u002Ftrackers\u002Fobd-data","pt":"\u002Fproducts\u002Ftrackers\u002Fobd-data"},"page_id":187},
    name: "products-trackers-obd-data-187___pt"
  }, {
    path: "/pt/products/trackers/professional",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fprofessional","ru":"\u002Fproducts\u002Ftrackers\u002Fprofessional","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fprofessional","es":"\u002Fproducts\u002Ftrackers\u002Fprofessional","pt":"\u002Fproducts\u002Ftrackers\u002Fprofessional"},"page_id":142},
    name: "products-trackers-professional-142___pt"
  }, {
    path: "/ru/products/accessories/can-adapters",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fcan-adapters","ru":"\u002Fproducts\u002Faccessories\u002Fcan-adapters","lt":"\u002Fproduktai\u002Fpriedai\u002Fcan-adapteriai","es":"\u002Fproducts\u002Faccessories\u002Fcan-adapters","pt":"\u002Fproducts\u002Faccessories\u002Fcan-adapters"},"page_id":178},
    name: "products-accessories-can-adapters-178___ru"
  }, {
    path: "/ru/products/accessories/data-cables",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fdata-cables","ru":"\u002Fproducts\u002Faccessories\u002Fdata-cables","lt":"\u002Fproduktai\u002Fpriedai\u002Fduomenu-perdavimo-kabeliai","es":"\u002Fproducts\u002Faccessories\u002Fdata-cables","pt":"\u002Fproducts\u002Faccessories\u002Fdata-cables"},"page_id":173},
    name: "products-accessories-data-cables-173___ru"
  }, {
    path: "/ru/products/accessories/other",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fother","ru":"\u002Fproducts\u002Faccessories\u002Fother","lt":"\u002Fproduktai\u002Fpriedai\u002Fkiti-priedai","es":"\u002Fproducts\u002Faccessories\u002Fother","pt":"\u002Fproducts\u002Faccessories\u002Fother"},"page_id":177},
    name: "products-accessories-other-177___ru"
  }, {
    path: "/ru/products/accessories/power-cables",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fpower-cables","ru":"\u002Fproducts\u002Faccessories\u002Fpower-cables","lt":"\u002Fproduktai\u002Fpriedai\u002Fmaitinimo-kabeliai","es":"\u002Fproducts\u002Faccessories\u002Fpower-cables","pt":"\u002Fproducts\u002Faccessories\u002Fpower-cables"},"page_id":175},
    name: "products-accessories-power-cables-175___ru"
  }, {
    path: "/ru/products/accessories/readers-holders",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Freaders-holders","ru":"\u002Fproducts\u002Faccessories\u002Freaders-holders","lt":"\u002Fproduktai\u002Fpriedai\u002Fskaitytuvai-ir-laikikliai","es":"\u002Fproducts\u002Faccessories\u002Freaders-holders","pt":"\u002Fproducts\u002Faccessories\u002Freaders-holders"},"page_id":174},
    name: "products-accessories-readers-holders-174___ru"
  }, {
    path: "/ru/products/accessories/sensors-beacons",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fsensors-beacons","ru":"\u002Fproducts\u002Faccessories\u002Fsensors-beacons","lt":"\u002Fproduktai\u002Fpriedai\u002Fjutikliai-ir-signalu-siustuvai","es":"\u002Fproducts\u002Faccessories\u002Fsensors-beacons","pt":"\u002Fproducts\u002Faccessories\u002Fsensors-beacons"},"page_id":172},
    name: "products-accessories-sensors-beacons-172___ru"
  }, {
    path: "/ru/products/accessories/video-telematics",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fvideo-telematics","ru":"\u002Fproducts\u002Faccessories\u002Fvideo-telematics","lt":"\u002Fproduktai\u002Fpriedai\u002Fvideo-telematika","es":"\u002Fproducts\u002Faccessories\u002Fvideo-telematics","pt":"\u002Fproducts\u002Faccessories\u002Fvideo-telematics"},"page_id":141},
    name: "products-accessories-video-telematics-141___ru"
  }, {
    path: "/ru/products/trackers/advanced",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fadvanced","ru":"\u002Fproducts\u002Ftrackers\u002Fadvanced","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fadvanced","es":"\u002Fproducts\u002Ftrackers\u002Fadvanced","pt":"\u002Fproducts\u002Ftrackers\u002Fadvanced"},"page_id":140},
    name: "products-trackers-advanced-140___ru"
  }, {
    path: "/ru/products/trackers/assets-workforce",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fassets-workforce","ru":"\u002Fproducts\u002Ftrackers\u002Fassets-workforce","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fobjektai-ir-personalas","es":"\u002Fproducts\u002Ftrackers\u002Fassets-workforce","pt":"\u002Fproducts\u002Ftrackers\u002Fassets-workforce"},"page_id":169},
    name: "products-trackers-assets-workforce-169___ru"
  }, {
    path: "/ru/products/trackers/basic",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fbasic","ru":"\u002Fproducts\u002Ftrackers\u002Fbasic","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fbasic","es":"\u002Fproducts\u002Ftrackers\u002Fbasic","pt":"\u002Fproducts\u002Ftrackers\u002Fbasic"},"page_id":138},
    name: "products-trackers-basic-138___ru"
  }, {
    path: "/ru/products/trackers/can-data",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fcan-data","ru":"\u002Fproducts\u002Ftrackers\u002Fcan-data","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fcan-data","es":"\u002Fproducts\u002Ftrackers\u002Fcan-data","pt":"\u002Fproducts\u002Ftrackers\u002Fcan-data"},"page_id":186},
    name: "products-trackers-can-data-186___ru"
  }, {
    path: "/ru/products/trackers/can-obd-data",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fcan-obd-data","ru":"\u002Fproducts\u002Ftrackers\u002Fcan-obd-data","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fcan-obd-duomenys","es":"\u002Fproducts\u002Ftrackers\u002Fcan-obd-data","pt":"\u002Fproducts\u002Ftrackers\u002Fcan-obd-data"},"page_id":143},
    name: "products-trackers-can-obd-data-143___ru"
  }, {
    path: "/ru/products/trackers/e-mobility",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fe-mobility","ru":"\u002Fproducts\u002Ftrackers\u002Fe-mobility","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fe-mobility","es":"\u002Fproducts\u002Ftrackers\u002Fe-mobility","pt":"\u002Fproducts\u002Ftrackers\u002Fe-mobility"},"page_id":188},
    name: "products-trackers-e-mobility-188___ru"
  }, {
    path: "/ru/products/trackers/fast-easy",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Ffast-easy","ru":"\u002Fproducts\u002Ftrackers\u002Ffast-easy","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Ffast-easy","es":"\u002Fproducts\u002Ftrackers\u002Ffast-easy","pt":"\u002Fproducts\u002Ftrackers\u002Ffast-easy"},"page_id":136},
    name: "products-trackers-fast-easy-136___ru"
  }, {
    path: "/ru/products/trackers/obd-data",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fobd-data","ru":"\u002Fproducts\u002Ftrackers\u002Fobd-data","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fobd-data","es":"\u002Fproducts\u002Ftrackers\u002Fobd-data","pt":"\u002Fproducts\u002Ftrackers\u002Fobd-data"},"page_id":187},
    name: "products-trackers-obd-data-187___ru"
  }, {
    path: "/ru/products/trackers/professional",
    component: _1b7d4af1,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fprofessional","ru":"\u002Fproducts\u002Ftrackers\u002Fprofessional","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fprofessional","es":"\u002Fproducts\u002Ftrackers\u002Fprofessional","pt":"\u002Fproducts\u002Ftrackers\u002Fprofessional"},"page_id":142},
    name: "products-trackers-professional-142___ru"
  }, {
    path: "/",
    component: _0934532a,
    meta: {"paths":{"en":"\u002F","ru":"\u002F","lt":"\u002F","es":"\u002F","pt":"\u002F"},"page_id":2},
    name: "teltonika-gps.com-landing-2___en"
  }, {
    path: "/es/products/accessories/can-adapters/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fcan-adapters\u002F:slug","ru":"\u002Fproducts\u002Faccessories\u002Fcan-adapters\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fcan-adapteriai\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fcan-adapters\u002F:slug","pt":"\u002Fproducts\u002Faccessories\u002Fcan-adapters\u002F:slug"},"page_id":178},
    name: "products-accessories-can-adapters-:slug-178-slug___es"
  }, {
    path: "/es/products/accessories/data-cables/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fdata-cables\u002F:slug","ru":"\u002Fproducts\u002Faccessories\u002Fdata-cables\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fduomenu-perdavimo-kabeliai\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fdata-cables\u002F:slug","pt":"\u002Fproducts\u002Faccessories\u002Fdata-cables\u002F:slug"},"page_id":173},
    name: "products-accessories-data-cables-:slug-173-slug___es"
  }, {
    path: "/es/products/accessories/other/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fother\u002F:slug","ru":"\u002Fproducts\u002Faccessories\u002Fother\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fkiti-priedai\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fother\u002F:slug","pt":"\u002Fproducts\u002Faccessories\u002Fother\u002F:slug"},"page_id":177},
    name: "products-accessories-other-:slug-177-slug___es"
  }, {
    path: "/es/products/accessories/power-cables/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fpower-cables\u002F:slug","ru":"\u002Fproducts\u002Faccessories\u002Fpower-cables\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fmaitinimo-kabeliai\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fpower-cables\u002F:slug","pt":"\u002Fproducts\u002Faccessories\u002Fpower-cables\u002F:slug"},"page_id":175},
    name: "products-accessories-power-cables-:slug-175-slug___es"
  }, {
    path: "/es/products/accessories/readers-holders/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Freaders-holders\u002F:slug","ru":"\u002Fproducts\u002Faccessories\u002Freaders-holders\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fskaitytuvai-ir-laikikliai\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Freaders-holders\u002F:slug","pt":"\u002Fproducts\u002Faccessories\u002Freaders-holders\u002F:slug"},"page_id":174},
    name: "products-accessories-readers-holders-:slug-174-slug___es"
  }, {
    path: "/es/products/accessories/sensors-beacons/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fsensors-beacons\u002F:slug","ru":"\u002Fproducts\u002Faccessories\u002Fsensors-beacons\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fjutikliai-ir-signalu-siustuvai\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fsensors-beacons\u002F:slug","pt":"\u002Fproducts\u002Faccessories\u002Fsensors-beacons\u002F:slug"},"page_id":172},
    name: "products-accessories-sensors-beacons-:slug-172-slug___es"
  }, {
    path: "/es/products/accessories/video-telematics/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fvideo-telematics\u002F:slug","ru":"\u002Fproducts\u002Faccessories\u002Fvideo-telematics\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fvideo-telematika\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fvideo-telematics\u002F:slug","pt":"\u002Fproducts\u002Faccessories\u002Fvideo-telematics\u002F:slug"},"page_id":141},
    name: "products-accessories-video-telematics-:slug-141-slug___es"
  }, {
    path: "/es/products/trackers/advanced/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fadvanced\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002Fadvanced\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fadvanced\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002Fadvanced\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002Fadvanced\u002F:slug"},"page_id":140},
    name: "products-trackers-advanced-:slug-140-slug___es"
  }, {
    path: "/es/products/trackers/assets-workforce/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fassets-workforce\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002Fassets-workforce\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fobjektai-ir-personalas\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002Fassets-workforce\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002Fassets-workforce\u002F:slug"},"page_id":169},
    name: "products-trackers-assets-workforce-:slug-169-slug___es"
  }, {
    path: "/es/products/trackers/basic/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fbasic\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002Fbasic\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fbasic\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002Fbasic\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002Fbasic\u002F:slug"},"page_id":138},
    name: "products-trackers-basic-:slug-138-slug___es"
  }, {
    path: "/es/products/trackers/can-data/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fcan-data\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002Fcan-data\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fcan-data\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002Fcan-data\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002Fcan-data\u002F:slug"},"page_id":186},
    name: "products-trackers-can-data-:slug-186-slug___es"
  }, {
    path: "/es/products/trackers/can-obd-data/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fcan-obd-data\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002Fcan-obd-data\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fcan-obd-duomenys\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002Fcan-obd-data\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002Fcan-obd-data\u002F:slug"},"page_id":143},
    name: "products-trackers-can-obd-data-:slug-143-slug___es"
  }, {
    path: "/es/products/trackers/e-mobility/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fe-mobility\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002Fe-mobility\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fe-mobility\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002Fe-mobility\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002Fe-mobility\u002F:slug"},"page_id":188},
    name: "products-trackers-e-mobility-:slug-188-slug___es"
  }, {
    path: "/es/products/trackers/fast-easy/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Ffast-easy\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002Ffast-easy\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Ffast-easy\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002Ffast-easy\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002Ffast-easy\u002F:slug"},"page_id":136},
    name: "products-trackers-fast-easy-:slug-136-slug___es"
  }, {
    path: "/es/products/trackers/obd-data/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fobd-data\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002Fobd-data\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fobd-data\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002Fobd-data\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002Fobd-data\u002F:slug"},"page_id":187},
    name: "products-trackers-obd-data-:slug-187-slug___es"
  }, {
    path: "/es/products/trackers/professional/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fprofessional\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002Fprofessional\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fprofessional\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002Fprofessional\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002Fprofessional\u002F:slug"},"page_id":142},
    name: "products-trackers-professional-:slug-142-slug___es"
  }, {
    path: "/lt/produktai/priedai/can-adapteriai/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fcan-adapters\u002F:slug","ru":"\u002Fproducts\u002Faccessories\u002Fcan-adapters\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fcan-adapteriai\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fcan-adapters\u002F:slug","pt":"\u002Fproducts\u002Faccessories\u002Fcan-adapters\u002F:slug"},"page_id":178},
    name: "products-accessories-can-adapters-:slug-178-slug___lt"
  }, {
    path: "/lt/produktai/priedai/duomenu-perdavimo-kabeliai/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fdata-cables\u002F:slug","ru":"\u002Fproducts\u002Faccessories\u002Fdata-cables\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fduomenu-perdavimo-kabeliai\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fdata-cables\u002F:slug","pt":"\u002Fproducts\u002Faccessories\u002Fdata-cables\u002F:slug"},"page_id":173},
    name: "products-accessories-data-cables-:slug-173-slug___lt"
  }, {
    path: "/lt/produktai/priedai/kiti-priedai/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fother\u002F:slug","ru":"\u002Fproducts\u002Faccessories\u002Fother\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fkiti-priedai\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fother\u002F:slug","pt":"\u002Fproducts\u002Faccessories\u002Fother\u002F:slug"},"page_id":177},
    name: "products-accessories-other-:slug-177-slug___lt"
  }, {
    path: "/lt/produktai/priedai/maitinimo-kabeliai/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fpower-cables\u002F:slug","ru":"\u002Fproducts\u002Faccessories\u002Fpower-cables\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fmaitinimo-kabeliai\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fpower-cables\u002F:slug","pt":"\u002Fproducts\u002Faccessories\u002Fpower-cables\u002F:slug"},"page_id":175},
    name: "products-accessories-power-cables-:slug-175-slug___lt"
  }, {
    path: "/lt/produktai/priedai/skaitytuvai-ir-laikikliai/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Freaders-holders\u002F:slug","ru":"\u002Fproducts\u002Faccessories\u002Freaders-holders\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fskaitytuvai-ir-laikikliai\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Freaders-holders\u002F:slug","pt":"\u002Fproducts\u002Faccessories\u002Freaders-holders\u002F:slug"},"page_id":174},
    name: "products-accessories-readers-holders-:slug-174-slug___lt"
  }, {
    path: "/lt/produktai/priedai/jutikliai-ir-signalu-siustuvai/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fsensors-beacons\u002F:slug","ru":"\u002Fproducts\u002Faccessories\u002Fsensors-beacons\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fjutikliai-ir-signalu-siustuvai\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fsensors-beacons\u002F:slug","pt":"\u002Fproducts\u002Faccessories\u002Fsensors-beacons\u002F:slug"},"page_id":172},
    name: "products-accessories-sensors-beacons-:slug-172-slug___lt"
  }, {
    path: "/lt/produktai/priedai/video-telematika/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fvideo-telematics\u002F:slug","ru":"\u002Fproducts\u002Faccessories\u002Fvideo-telematics\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fvideo-telematika\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fvideo-telematics\u002F:slug","pt":"\u002Fproducts\u002Faccessories\u002Fvideo-telematics\u002F:slug"},"page_id":141},
    name: "products-accessories-video-telematics-:slug-141-slug___lt"
  }, {
    path: "/lt/produktai/sekimo-iranga/advanced/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fadvanced\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002Fadvanced\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fadvanced\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002Fadvanced\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002Fadvanced\u002F:slug"},"page_id":140},
    name: "products-trackers-advanced-:slug-140-slug___lt"
  }, {
    path: "/lt/produktai/sekimo-iranga/objektai-ir-personalas/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fassets-workforce\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002Fassets-workforce\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fobjektai-ir-personalas\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002Fassets-workforce\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002Fassets-workforce\u002F:slug"},"page_id":169},
    name: "products-trackers-assets-workforce-:slug-169-slug___lt"
  }, {
    path: "/lt/produktai/sekimo-iranga/basic/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fbasic\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002Fbasic\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fbasic\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002Fbasic\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002Fbasic\u002F:slug"},"page_id":138},
    name: "products-trackers-basic-:slug-138-slug___lt"
  }, {
    path: "/lt/produktai/sekimo-iranga/can-data/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fcan-data\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002Fcan-data\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fcan-data\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002Fcan-data\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002Fcan-data\u002F:slug"},"page_id":186},
    name: "products-trackers-can-data-:slug-186-slug___lt"
  }, {
    path: "/lt/produktai/sekimo-iranga/can-obd-duomenys/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fcan-obd-data\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002Fcan-obd-data\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fcan-obd-duomenys\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002Fcan-obd-data\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002Fcan-obd-data\u002F:slug"},"page_id":143},
    name: "products-trackers-can-obd-data-:slug-143-slug___lt"
  }, {
    path: "/lt/produktai/sekimo-iranga/e-mobility/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fe-mobility\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002Fe-mobility\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fe-mobility\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002Fe-mobility\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002Fe-mobility\u002F:slug"},"page_id":188},
    name: "products-trackers-e-mobility-:slug-188-slug___lt"
  }, {
    path: "/lt/produktai/sekimo-iranga/fast-easy/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Ffast-easy\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002Ffast-easy\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Ffast-easy\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002Ffast-easy\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002Ffast-easy\u002F:slug"},"page_id":136},
    name: "products-trackers-fast-easy-:slug-136-slug___lt"
  }, {
    path: "/lt/produktai/sekimo-iranga/obd-data/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fobd-data\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002Fobd-data\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fobd-data\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002Fobd-data\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002Fobd-data\u002F:slug"},"page_id":187},
    name: "products-trackers-obd-data-:slug-187-slug___lt"
  }, {
    path: "/lt/produktai/sekimo-iranga/professional/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fprofessional\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002Fprofessional\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fprofessional\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002Fprofessional\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002Fprofessional\u002F:slug"},"page_id":142},
    name: "products-trackers-professional-:slug-142-slug___lt"
  }, {
    path: "/pt/products/accessories/can-adapters/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fcan-adapters\u002F:slug","ru":"\u002Fproducts\u002Faccessories\u002Fcan-adapters\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fcan-adapteriai\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fcan-adapters\u002F:slug","pt":"\u002Fproducts\u002Faccessories\u002Fcan-adapters\u002F:slug"},"page_id":178},
    name: "products-accessories-can-adapters-:slug-178-slug___pt"
  }, {
    path: "/pt/products/accessories/data-cables/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fdata-cables\u002F:slug","ru":"\u002Fproducts\u002Faccessories\u002Fdata-cables\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fduomenu-perdavimo-kabeliai\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fdata-cables\u002F:slug","pt":"\u002Fproducts\u002Faccessories\u002Fdata-cables\u002F:slug"},"page_id":173},
    name: "products-accessories-data-cables-:slug-173-slug___pt"
  }, {
    path: "/pt/products/accessories/other/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fother\u002F:slug","ru":"\u002Fproducts\u002Faccessories\u002Fother\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fkiti-priedai\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fother\u002F:slug","pt":"\u002Fproducts\u002Faccessories\u002Fother\u002F:slug"},"page_id":177},
    name: "products-accessories-other-:slug-177-slug___pt"
  }, {
    path: "/pt/products/accessories/power-cables/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fpower-cables\u002F:slug","ru":"\u002Fproducts\u002Faccessories\u002Fpower-cables\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fmaitinimo-kabeliai\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fpower-cables\u002F:slug","pt":"\u002Fproducts\u002Faccessories\u002Fpower-cables\u002F:slug"},"page_id":175},
    name: "products-accessories-power-cables-:slug-175-slug___pt"
  }, {
    path: "/pt/products/accessories/readers-holders/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Freaders-holders\u002F:slug","ru":"\u002Fproducts\u002Faccessories\u002Freaders-holders\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fskaitytuvai-ir-laikikliai\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Freaders-holders\u002F:slug","pt":"\u002Fproducts\u002Faccessories\u002Freaders-holders\u002F:slug"},"page_id":174},
    name: "products-accessories-readers-holders-:slug-174-slug___pt"
  }, {
    path: "/pt/products/accessories/sensors-beacons/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fsensors-beacons\u002F:slug","ru":"\u002Fproducts\u002Faccessories\u002Fsensors-beacons\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fjutikliai-ir-signalu-siustuvai\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fsensors-beacons\u002F:slug","pt":"\u002Fproducts\u002Faccessories\u002Fsensors-beacons\u002F:slug"},"page_id":172},
    name: "products-accessories-sensors-beacons-:slug-172-slug___pt"
  }, {
    path: "/pt/products/accessories/video-telematics/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fvideo-telematics\u002F:slug","ru":"\u002Fproducts\u002Faccessories\u002Fvideo-telematics\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fvideo-telematika\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fvideo-telematics\u002F:slug","pt":"\u002Fproducts\u002Faccessories\u002Fvideo-telematics\u002F:slug"},"page_id":141},
    name: "products-accessories-video-telematics-:slug-141-slug___pt"
  }, {
    path: "/pt/products/trackers/advanced/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fadvanced\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002Fadvanced\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fadvanced\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002Fadvanced\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002Fadvanced\u002F:slug"},"page_id":140},
    name: "products-trackers-advanced-:slug-140-slug___pt"
  }, {
    path: "/pt/products/trackers/assets-workforce/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fassets-workforce\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002Fassets-workforce\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fobjektai-ir-personalas\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002Fassets-workforce\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002Fassets-workforce\u002F:slug"},"page_id":169},
    name: "products-trackers-assets-workforce-:slug-169-slug___pt"
  }, {
    path: "/pt/products/trackers/basic/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fbasic\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002Fbasic\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fbasic\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002Fbasic\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002Fbasic\u002F:slug"},"page_id":138},
    name: "products-trackers-basic-:slug-138-slug___pt"
  }, {
    path: "/pt/products/trackers/can-data/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fcan-data\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002Fcan-data\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fcan-data\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002Fcan-data\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002Fcan-data\u002F:slug"},"page_id":186},
    name: "products-trackers-can-data-:slug-186-slug___pt"
  }, {
    path: "/pt/products/trackers/can-obd-data/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fcan-obd-data\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002Fcan-obd-data\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fcan-obd-duomenys\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002Fcan-obd-data\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002Fcan-obd-data\u002F:slug"},"page_id":143},
    name: "products-trackers-can-obd-data-:slug-143-slug___pt"
  }, {
    path: "/pt/products/trackers/e-mobility/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fe-mobility\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002Fe-mobility\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fe-mobility\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002Fe-mobility\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002Fe-mobility\u002F:slug"},"page_id":188},
    name: "products-trackers-e-mobility-:slug-188-slug___pt"
  }, {
    path: "/pt/products/trackers/fast-easy/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Ffast-easy\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002Ffast-easy\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Ffast-easy\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002Ffast-easy\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002Ffast-easy\u002F:slug"},"page_id":136},
    name: "products-trackers-fast-easy-:slug-136-slug___pt"
  }, {
    path: "/pt/products/trackers/obd-data/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fobd-data\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002Fobd-data\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fobd-data\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002Fobd-data\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002Fobd-data\u002F:slug"},"page_id":187},
    name: "products-trackers-obd-data-:slug-187-slug___pt"
  }, {
    path: "/pt/products/trackers/professional/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fprofessional\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002Fprofessional\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fprofessional\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002Fprofessional\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002Fprofessional\u002F:slug"},"page_id":142},
    name: "products-trackers-professional-:slug-142-slug___pt"
  }, {
    path: "/ru/products/accessories/can-adapters/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fcan-adapters\u002F:slug","ru":"\u002Fproducts\u002Faccessories\u002Fcan-adapters\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fcan-adapteriai\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fcan-adapters\u002F:slug","pt":"\u002Fproducts\u002Faccessories\u002Fcan-adapters\u002F:slug"},"page_id":178},
    name: "products-accessories-can-adapters-:slug-178-slug___ru"
  }, {
    path: "/ru/products/accessories/data-cables/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fdata-cables\u002F:slug","ru":"\u002Fproducts\u002Faccessories\u002Fdata-cables\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fduomenu-perdavimo-kabeliai\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fdata-cables\u002F:slug","pt":"\u002Fproducts\u002Faccessories\u002Fdata-cables\u002F:slug"},"page_id":173},
    name: "products-accessories-data-cables-:slug-173-slug___ru"
  }, {
    path: "/ru/products/accessories/other/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fother\u002F:slug","ru":"\u002Fproducts\u002Faccessories\u002Fother\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fkiti-priedai\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fother\u002F:slug","pt":"\u002Fproducts\u002Faccessories\u002Fother\u002F:slug"},"page_id":177},
    name: "products-accessories-other-:slug-177-slug___ru"
  }, {
    path: "/ru/products/accessories/power-cables/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fpower-cables\u002F:slug","ru":"\u002Fproducts\u002Faccessories\u002Fpower-cables\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fmaitinimo-kabeliai\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fpower-cables\u002F:slug","pt":"\u002Fproducts\u002Faccessories\u002Fpower-cables\u002F:slug"},"page_id":175},
    name: "products-accessories-power-cables-:slug-175-slug___ru"
  }, {
    path: "/ru/products/accessories/readers-holders/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Freaders-holders\u002F:slug","ru":"\u002Fproducts\u002Faccessories\u002Freaders-holders\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fskaitytuvai-ir-laikikliai\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Freaders-holders\u002F:slug","pt":"\u002Fproducts\u002Faccessories\u002Freaders-holders\u002F:slug"},"page_id":174},
    name: "products-accessories-readers-holders-:slug-174-slug___ru"
  }, {
    path: "/ru/products/accessories/sensors-beacons/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fsensors-beacons\u002F:slug","ru":"\u002Fproducts\u002Faccessories\u002Fsensors-beacons\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fjutikliai-ir-signalu-siustuvai\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fsensors-beacons\u002F:slug","pt":"\u002Fproducts\u002Faccessories\u002Fsensors-beacons\u002F:slug"},"page_id":172},
    name: "products-accessories-sensors-beacons-:slug-172-slug___ru"
  }, {
    path: "/ru/products/accessories/video-telematics/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fvideo-telematics\u002F:slug","ru":"\u002Fproducts\u002Faccessories\u002Fvideo-telematics\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fvideo-telematika\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fvideo-telematics\u002F:slug","pt":"\u002Fproducts\u002Faccessories\u002Fvideo-telematics\u002F:slug"},"page_id":141},
    name: "products-accessories-video-telematics-:slug-141-slug___ru"
  }, {
    path: "/ru/products/trackers/advanced/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fadvanced\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002Fadvanced\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fadvanced\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002Fadvanced\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002Fadvanced\u002F:slug"},"page_id":140},
    name: "products-trackers-advanced-:slug-140-slug___ru"
  }, {
    path: "/ru/products/trackers/assets-workforce/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fassets-workforce\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002Fassets-workforce\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fobjektai-ir-personalas\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002Fassets-workforce\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002Fassets-workforce\u002F:slug"},"page_id":169},
    name: "products-trackers-assets-workforce-:slug-169-slug___ru"
  }, {
    path: "/ru/products/trackers/basic/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fbasic\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002Fbasic\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fbasic\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002Fbasic\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002Fbasic\u002F:slug"},"page_id":138},
    name: "products-trackers-basic-:slug-138-slug___ru"
  }, {
    path: "/ru/products/trackers/can-data/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fcan-data\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002Fcan-data\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fcan-data\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002Fcan-data\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002Fcan-data\u002F:slug"},"page_id":186},
    name: "products-trackers-can-data-:slug-186-slug___ru"
  }, {
    path: "/ru/products/trackers/can-obd-data/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fcan-obd-data\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002Fcan-obd-data\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fcan-obd-duomenys\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002Fcan-obd-data\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002Fcan-obd-data\u002F:slug"},"page_id":143},
    name: "products-trackers-can-obd-data-:slug-143-slug___ru"
  }, {
    path: "/ru/products/trackers/e-mobility/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fe-mobility\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002Fe-mobility\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fe-mobility\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002Fe-mobility\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002Fe-mobility\u002F:slug"},"page_id":188},
    name: "products-trackers-e-mobility-:slug-188-slug___ru"
  }, {
    path: "/ru/products/trackers/fast-easy/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Ffast-easy\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002Ffast-easy\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Ffast-easy\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002Ffast-easy\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002Ffast-easy\u002F:slug"},"page_id":136},
    name: "products-trackers-fast-easy-:slug-136-slug___ru"
  }, {
    path: "/ru/products/trackers/obd-data/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fobd-data\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002Fobd-data\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fobd-data\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002Fobd-data\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002Fobd-data\u002F:slug"},"page_id":187},
    name: "products-trackers-obd-data-:slug-187-slug___ru"
  }, {
    path: "/ru/products/trackers/professional/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fprofessional\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002Fprofessional\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fprofessional\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002Fprofessional\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002Fprofessional\u002F:slug"},"page_id":142},
    name: "products-trackers-professional-:slug-142-slug___ru"
  }, {
    path: "/es/about-us/policies-certificates/:slug",
    component: _8d5b5624,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","ru":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai\u002F:slug","es":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","pt":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug"},"page_id":18},
    name: "about-us-policies-certificates-:slug-18-slug___es"
  }, {
    path: "/es/products/accessories/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002F:slug","ru":"\u002Fproducts\u002Faccessories\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002F:slug","pt":"\u002Fproducts\u002Faccessories\u002F:slug"},"page_id":139},
    name: "products-accessories-:slug-139-slug___es"
  }, {
    path: "/es/products/solutions/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Fsolutions\u002F:slug","ru":"\u002Fproducts\u002Fsolutions\u002F:slug","lt":"\u002Fproduktai\u002Fsprendimai\u002F:slug","es":"\u002Fproducts\u002Fsolutions\u002F:slug","pt":"\u002Fproducts\u002Fsolutions\u002F:slug"},"page_id":137},
    name: "products-solutions-:slug-137-slug___es"
  }, {
    path: "/es/products/trackers/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002F:slug"},"page_id":135},
    name: "products-trackers-:slug-135-slug___es"
  }, {
    path: "/es/resources/videos/:slug",
    component: _0c947502,
    meta: {"paths":{"en":"\u002Fresources\u002Fvideos\u002F:slug","ru":"\u002Fresources\u002Fvideos\u002F:slug","lt":"\u002Fresursai\u002Fvaizdo-irasai\u002F:slug","es":"\u002Fresources\u002Fvideos\u002F:slug","pt":"\u002Fresources\u002Fvideos\u002F:slug"},"page_id":155},
    name: "resources-videos-:slug-155-slug___es"
  }, {
    path: "/es/resources/webinars/:slug",
    component: _2c6c8c4b,
    meta: {"paths":{"en":"\u002Fresources\u002Fwebinars\u002F:slug","ru":"\u002Fresources\u002Fwebinars\u002F:slug","lt":"\u002Fresursai\u002Fwebinarai\u002F:slug","es":"\u002Fresources\u002Fwebinars\u002F:slug","pt":"\u002Fresources\u002Fwebinars\u002F:slug"},"page_id":100},
    name: "resources-webinars-:slug-100-slug___es"
  }, {
    path: "/es/use-cases/agriculture/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fagriculture\u002F:slug","ru":"\u002Fuse-cases\u002Fagriculture\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002Fzemes-ukis\u002F:slug","es":"\u002Fuse-cases\u002Fagriculture\u002F:slug","pt":"\u002Fuse-cases\u002Fagriculture\u002F:slug"},"page_id":146},
    name: "use-cases-agriculture-:slug-146-slug___es"
  }, {
    path: "/es/use-cases/assets-and-workforce/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fassets-and-workforce\u002F:slug","ru":"\u002Fuse-cases\u002Fassets-and-workforce\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002Fobjektai-ir-personalas-1\u002F:slug","es":"\u002Fuse-cases\u002Fassets-and-workforce\u002F:slug","pt":"\u002Fuse-cases\u002Fassets-and-workforce\u002F:slug"},"page_id":193},
    name: "use-cases-assets-and-workforce-:slug-193-slug___es"
  }, {
    path: "/es/use-cases/car-sharing-rental/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fcar-sharing-rental\u002F:slug","ru":"\u002Fuse-cases\u002Fcar-sharing-rental\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002Fautomobiliu-nuoma-ir-dalijimasis\u002F:slug","es":"\u002Fuse-cases\u002Fcar-sharing-rental\u002F:slug","pt":"\u002Fuse-cases\u002Fcar-sharing-rental\u002F:slug"},"page_id":147},
    name: "use-cases-car-sharing-rental-:slug-147-slug___es"
  }, {
    path: "/es/use-cases/construction-mining/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fconstruction-mining\u002F:slug","ru":"\u002Fuse-cases\u002Fconstruction-mining\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002Fstatyba-ir-kalnakasyba\u002F:slug","es":"\u002Fuse-cases\u002Fconstruction-mining\u002F:slug","pt":"\u002Fuse-cases\u002Fconstruction-mining\u002F:slug"},"page_id":149},
    name: "use-cases-construction-mining-:slug-149-slug___es"
  }, {
    path: "/es/use-cases/driver-safety/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fdriver-safety\u002F:slug","ru":"\u002Fuse-cases\u002Fdriver-safety\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002Fvairuotoju-sauga\u002F:slug","es":"\u002Fuse-cases\u002Fdriver-safety\u002F:slug","pt":"\u002Fuse-cases\u002Fdriver-safety\u002F:slug"},"page_id":194},
    name: "use-cases-driver-safety-:slug-194-slug___es"
  }, {
    path: "/es/use-cases/emobility-management/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Femobility-management\u002F:slug","ru":"\u002Fuse-cases\u002Femobility-management\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002Femobilumas\u002F:slug","es":"\u002Fuse-cases\u002Femobility-management\u002F:slug","pt":"\u002Fuse-cases\u002Femobility-management\u002F:slug"},"page_id":190},
    name: "use-cases-emobility-management-:slug-190-slug___es"
  }, {
    path: "/es/use-cases/logistics/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Flogistics\u002F:slug","ru":"\u002Fuse-cases\u002Flogistics\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002Flogistika\u002F:slug","es":"\u002Fuse-cases\u002Flogistics\u002F:slug","pt":"\u002Fuse-cases\u002Flogistics\u002F:slug"},"page_id":150},
    name: "use-cases-logistics-:slug-150-slug___es"
  }, {
    path: "/es/use-cases/telematics/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Ftelematics\u002F:slug","ru":"\u002Fuse-cases\u002Ftelematics\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002Ftelematika\u002F:slug","es":"\u002Fuse-cases\u002Ftelematics\u002F:slug","pt":"\u002Fuse-cases\u002Ftelematics\u002F:slug"},"page_id":144},
    name: "use-cases-telematics-:slug-144-slug___es"
  }, {
    path: "/es/use-cases/utility-services/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Futility-services\u002F:slug","ru":"\u002Fuse-cases\u002Futility-services\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002Fkomunalines-paslaugos\u002F:slug","es":"\u002Fuse-cases\u002Futility-services\u002F:slug","pt":"\u002Fuse-cases\u002Futility-services\u002F:slug"},"page_id":145},
    name: "use-cases-utility-services-:slug-145-slug___es"
  }, {
    path: "/lt/apie-mus/politika-ir-sertifikatai/:slug",
    component: _8d5b5624,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","ru":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai\u002F:slug","es":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","pt":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug"},"page_id":18},
    name: "about-us-policies-certificates-:slug-18-slug___lt"
  }, {
    path: "/lt/produktai/priedai/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002F:slug","ru":"\u002Fproducts\u002Faccessories\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002F:slug","pt":"\u002Fproducts\u002Faccessories\u002F:slug"},"page_id":139},
    name: "products-accessories-:slug-139-slug___lt"
  }, {
    path: "/lt/produktai/sprendimai/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Fsolutions\u002F:slug","ru":"\u002Fproducts\u002Fsolutions\u002F:slug","lt":"\u002Fproduktai\u002Fsprendimai\u002F:slug","es":"\u002Fproducts\u002Fsolutions\u002F:slug","pt":"\u002Fproducts\u002Fsolutions\u002F:slug"},"page_id":137},
    name: "products-solutions-:slug-137-slug___lt"
  }, {
    path: "/lt/produktai/sekimo-iranga/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002F:slug"},"page_id":135},
    name: "products-trackers-:slug-135-slug___lt"
  }, {
    path: "/lt/resursai/vaizdo-irasai/:slug",
    component: _0c947502,
    meta: {"paths":{"en":"\u002Fresources\u002Fvideos\u002F:slug","ru":"\u002Fresources\u002Fvideos\u002F:slug","lt":"\u002Fresursai\u002Fvaizdo-irasai\u002F:slug","es":"\u002Fresources\u002Fvideos\u002F:slug","pt":"\u002Fresources\u002Fvideos\u002F:slug"},"page_id":155},
    name: "resources-videos-:slug-155-slug___lt"
  }, {
    path: "/lt/resursai/webinarai/:slug",
    component: _2c6c8c4b,
    meta: {"paths":{"en":"\u002Fresources\u002Fwebinars\u002F:slug","ru":"\u002Fresources\u002Fwebinars\u002F:slug","lt":"\u002Fresursai\u002Fwebinarai\u002F:slug","es":"\u002Fresources\u002Fwebinars\u002F:slug","pt":"\u002Fresources\u002Fwebinars\u002F:slug"},"page_id":100},
    name: "resources-webinars-:slug-100-slug___lt"
  }, {
    path: "/lt/pritaikymo-sritys/zemes-ukis/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fagriculture\u002F:slug","ru":"\u002Fuse-cases\u002Fagriculture\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002Fzemes-ukis\u002F:slug","es":"\u002Fuse-cases\u002Fagriculture\u002F:slug","pt":"\u002Fuse-cases\u002Fagriculture\u002F:slug"},"page_id":146},
    name: "use-cases-agriculture-:slug-146-slug___lt"
  }, {
    path: "/lt/pritaikymo-sritys/objektai-ir-personalas-1/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fassets-and-workforce\u002F:slug","ru":"\u002Fuse-cases\u002Fassets-and-workforce\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002Fobjektai-ir-personalas-1\u002F:slug","es":"\u002Fuse-cases\u002Fassets-and-workforce\u002F:slug","pt":"\u002Fuse-cases\u002Fassets-and-workforce\u002F:slug"},"page_id":193},
    name: "use-cases-assets-and-workforce-:slug-193-slug___lt"
  }, {
    path: "/lt/pritaikymo-sritys/automobiliu-nuoma-ir-dalijimasis/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fcar-sharing-rental\u002F:slug","ru":"\u002Fuse-cases\u002Fcar-sharing-rental\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002Fautomobiliu-nuoma-ir-dalijimasis\u002F:slug","es":"\u002Fuse-cases\u002Fcar-sharing-rental\u002F:slug","pt":"\u002Fuse-cases\u002Fcar-sharing-rental\u002F:slug"},"page_id":147},
    name: "use-cases-car-sharing-rental-:slug-147-slug___lt"
  }, {
    path: "/lt/pritaikymo-sritys/statyba-ir-kalnakasyba/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fconstruction-mining\u002F:slug","ru":"\u002Fuse-cases\u002Fconstruction-mining\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002Fstatyba-ir-kalnakasyba\u002F:slug","es":"\u002Fuse-cases\u002Fconstruction-mining\u002F:slug","pt":"\u002Fuse-cases\u002Fconstruction-mining\u002F:slug"},"page_id":149},
    name: "use-cases-construction-mining-:slug-149-slug___lt"
  }, {
    path: "/lt/pritaikymo-sritys/vairuotoju-sauga/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fdriver-safety\u002F:slug","ru":"\u002Fuse-cases\u002Fdriver-safety\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002Fvairuotoju-sauga\u002F:slug","es":"\u002Fuse-cases\u002Fdriver-safety\u002F:slug","pt":"\u002Fuse-cases\u002Fdriver-safety\u002F:slug"},"page_id":194},
    name: "use-cases-driver-safety-:slug-194-slug___lt"
  }, {
    path: "/lt/pritaikymo-sritys/emobilumas/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Femobility-management\u002F:slug","ru":"\u002Fuse-cases\u002Femobility-management\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002Femobilumas\u002F:slug","es":"\u002Fuse-cases\u002Femobility-management\u002F:slug","pt":"\u002Fuse-cases\u002Femobility-management\u002F:slug"},"page_id":190},
    name: "use-cases-emobility-management-:slug-190-slug___lt"
  }, {
    path: "/lt/pritaikymo-sritys/logistika/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Flogistics\u002F:slug","ru":"\u002Fuse-cases\u002Flogistics\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002Flogistika\u002F:slug","es":"\u002Fuse-cases\u002Flogistics\u002F:slug","pt":"\u002Fuse-cases\u002Flogistics\u002F:slug"},"page_id":150},
    name: "use-cases-logistics-:slug-150-slug___lt"
  }, {
    path: "/lt/pritaikymo-sritys/telematika/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Ftelematics\u002F:slug","ru":"\u002Fuse-cases\u002Ftelematics\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002Ftelematika\u002F:slug","es":"\u002Fuse-cases\u002Ftelematics\u002F:slug","pt":"\u002Fuse-cases\u002Ftelematics\u002F:slug"},"page_id":144},
    name: "use-cases-telematics-:slug-144-slug___lt"
  }, {
    path: "/lt/pritaikymo-sritys/komunalines-paslaugos/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Futility-services\u002F:slug","ru":"\u002Fuse-cases\u002Futility-services\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002Fkomunalines-paslaugos\u002F:slug","es":"\u002Fuse-cases\u002Futility-services\u002F:slug","pt":"\u002Fuse-cases\u002Futility-services\u002F:slug"},"page_id":145},
    name: "use-cases-utility-services-:slug-145-slug___lt"
  }, {
    path: "/products/accessories/can-adapters/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fcan-adapters\u002F:slug","ru":"\u002Fproducts\u002Faccessories\u002Fcan-adapters\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fcan-adapteriai\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fcan-adapters\u002F:slug","pt":"\u002Fproducts\u002Faccessories\u002Fcan-adapters\u002F:slug"},"page_id":178},
    name: "products-accessories-can-adapters-:slug-178-slug___en"
  }, {
    path: "/products/accessories/data-cables/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fdata-cables\u002F:slug","ru":"\u002Fproducts\u002Faccessories\u002Fdata-cables\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fduomenu-perdavimo-kabeliai\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fdata-cables\u002F:slug","pt":"\u002Fproducts\u002Faccessories\u002Fdata-cables\u002F:slug"},"page_id":173},
    name: "products-accessories-data-cables-:slug-173-slug___en"
  }, {
    path: "/products/accessories/other/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fother\u002F:slug","ru":"\u002Fproducts\u002Faccessories\u002Fother\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fkiti-priedai\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fother\u002F:slug","pt":"\u002Fproducts\u002Faccessories\u002Fother\u002F:slug"},"page_id":177},
    name: "products-accessories-other-:slug-177-slug___en"
  }, {
    path: "/products/accessories/power-cables/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fpower-cables\u002F:slug","ru":"\u002Fproducts\u002Faccessories\u002Fpower-cables\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fmaitinimo-kabeliai\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fpower-cables\u002F:slug","pt":"\u002Fproducts\u002Faccessories\u002Fpower-cables\u002F:slug"},"page_id":175},
    name: "products-accessories-power-cables-:slug-175-slug___en"
  }, {
    path: "/products/accessories/readers-holders/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Freaders-holders\u002F:slug","ru":"\u002Fproducts\u002Faccessories\u002Freaders-holders\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fskaitytuvai-ir-laikikliai\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Freaders-holders\u002F:slug","pt":"\u002Fproducts\u002Faccessories\u002Freaders-holders\u002F:slug"},"page_id":174},
    name: "products-accessories-readers-holders-:slug-174-slug___en"
  }, {
    path: "/products/accessories/sensors-beacons/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fsensors-beacons\u002F:slug","ru":"\u002Fproducts\u002Faccessories\u002Fsensors-beacons\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fjutikliai-ir-signalu-siustuvai\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fsensors-beacons\u002F:slug","pt":"\u002Fproducts\u002Faccessories\u002Fsensors-beacons\u002F:slug"},"page_id":172},
    name: "products-accessories-sensors-beacons-:slug-172-slug___en"
  }, {
    path: "/products/accessories/video-telematics/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fvideo-telematics\u002F:slug","ru":"\u002Fproducts\u002Faccessories\u002Fvideo-telematics\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fvideo-telematika\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fvideo-telematics\u002F:slug","pt":"\u002Fproducts\u002Faccessories\u002Fvideo-telematics\u002F:slug"},"page_id":141},
    name: "products-accessories-video-telematics-:slug-141-slug___en"
  }, {
    path: "/products/trackers/advanced/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fadvanced\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002Fadvanced\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fadvanced\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002Fadvanced\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002Fadvanced\u002F:slug"},"page_id":140},
    name: "products-trackers-advanced-:slug-140-slug___en"
  }, {
    path: "/products/trackers/assets-workforce/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fassets-workforce\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002Fassets-workforce\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fobjektai-ir-personalas\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002Fassets-workforce\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002Fassets-workforce\u002F:slug"},"page_id":169},
    name: "products-trackers-assets-workforce-:slug-169-slug___en"
  }, {
    path: "/products/trackers/basic/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fbasic\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002Fbasic\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fbasic\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002Fbasic\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002Fbasic\u002F:slug"},"page_id":138},
    name: "products-trackers-basic-:slug-138-slug___en"
  }, {
    path: "/products/trackers/can-data/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fcan-data\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002Fcan-data\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fcan-data\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002Fcan-data\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002Fcan-data\u002F:slug"},"page_id":186},
    name: "products-trackers-can-data-:slug-186-slug___en"
  }, {
    path: "/products/trackers/can-obd-data/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fcan-obd-data\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002Fcan-obd-data\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fcan-obd-duomenys\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002Fcan-obd-data\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002Fcan-obd-data\u002F:slug"},"page_id":143},
    name: "products-trackers-can-obd-data-:slug-143-slug___en"
  }, {
    path: "/products/trackers/e-mobility/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fe-mobility\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002Fe-mobility\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fe-mobility\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002Fe-mobility\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002Fe-mobility\u002F:slug"},"page_id":188},
    name: "products-trackers-e-mobility-:slug-188-slug___en"
  }, {
    path: "/products/trackers/fast-easy/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Ffast-easy\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002Ffast-easy\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Ffast-easy\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002Ffast-easy\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002Ffast-easy\u002F:slug"},"page_id":136},
    name: "products-trackers-fast-easy-:slug-136-slug___en"
  }, {
    path: "/products/trackers/obd-data/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fobd-data\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002Fobd-data\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fobd-data\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002Fobd-data\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002Fobd-data\u002F:slug"},"page_id":187},
    name: "products-trackers-obd-data-:slug-187-slug___en"
  }, {
    path: "/products/trackers/professional/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002Fprofessional\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002Fprofessional\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002Fprofessional\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002Fprofessional\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002Fprofessional\u002F:slug"},"page_id":142},
    name: "products-trackers-professional-:slug-142-slug___en"
  }, {
    path: "/pt/about-us/policies-certificates/:slug",
    component: _8d5b5624,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","ru":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai\u002F:slug","es":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","pt":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug"},"page_id":18},
    name: "about-us-policies-certificates-:slug-18-slug___pt"
  }, {
    path: "/pt/products/accessories/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002F:slug","ru":"\u002Fproducts\u002Faccessories\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002F:slug","pt":"\u002Fproducts\u002Faccessories\u002F:slug"},"page_id":139},
    name: "products-accessories-:slug-139-slug___pt"
  }, {
    path: "/pt/products/solutions/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Fsolutions\u002F:slug","ru":"\u002Fproducts\u002Fsolutions\u002F:slug","lt":"\u002Fproduktai\u002Fsprendimai\u002F:slug","es":"\u002Fproducts\u002Fsolutions\u002F:slug","pt":"\u002Fproducts\u002Fsolutions\u002F:slug"},"page_id":137},
    name: "products-solutions-:slug-137-slug___pt"
  }, {
    path: "/pt/products/trackers/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002F:slug"},"page_id":135},
    name: "products-trackers-:slug-135-slug___pt"
  }, {
    path: "/pt/resources/videos/:slug",
    component: _0c947502,
    meta: {"paths":{"en":"\u002Fresources\u002Fvideos\u002F:slug","ru":"\u002Fresources\u002Fvideos\u002F:slug","lt":"\u002Fresursai\u002Fvaizdo-irasai\u002F:slug","es":"\u002Fresources\u002Fvideos\u002F:slug","pt":"\u002Fresources\u002Fvideos\u002F:slug"},"page_id":155},
    name: "resources-videos-:slug-155-slug___pt"
  }, {
    path: "/pt/resources/webinars/:slug",
    component: _2c6c8c4b,
    meta: {"paths":{"en":"\u002Fresources\u002Fwebinars\u002F:slug","ru":"\u002Fresources\u002Fwebinars\u002F:slug","lt":"\u002Fresursai\u002Fwebinarai\u002F:slug","es":"\u002Fresources\u002Fwebinars\u002F:slug","pt":"\u002Fresources\u002Fwebinars\u002F:slug"},"page_id":100},
    name: "resources-webinars-:slug-100-slug___pt"
  }, {
    path: "/pt/use-cases/agriculture/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fagriculture\u002F:slug","ru":"\u002Fuse-cases\u002Fagriculture\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002Fzemes-ukis\u002F:slug","es":"\u002Fuse-cases\u002Fagriculture\u002F:slug","pt":"\u002Fuse-cases\u002Fagriculture\u002F:slug"},"page_id":146},
    name: "use-cases-agriculture-:slug-146-slug___pt"
  }, {
    path: "/pt/use-cases/assets-and-workforce/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fassets-and-workforce\u002F:slug","ru":"\u002Fuse-cases\u002Fassets-and-workforce\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002Fobjektai-ir-personalas-1\u002F:slug","es":"\u002Fuse-cases\u002Fassets-and-workforce\u002F:slug","pt":"\u002Fuse-cases\u002Fassets-and-workforce\u002F:slug"},"page_id":193},
    name: "use-cases-assets-and-workforce-:slug-193-slug___pt"
  }, {
    path: "/pt/use-cases/car-sharing-rental/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fcar-sharing-rental\u002F:slug","ru":"\u002Fuse-cases\u002Fcar-sharing-rental\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002Fautomobiliu-nuoma-ir-dalijimasis\u002F:slug","es":"\u002Fuse-cases\u002Fcar-sharing-rental\u002F:slug","pt":"\u002Fuse-cases\u002Fcar-sharing-rental\u002F:slug"},"page_id":147},
    name: "use-cases-car-sharing-rental-:slug-147-slug___pt"
  }, {
    path: "/pt/use-cases/construction-mining/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fconstruction-mining\u002F:slug","ru":"\u002Fuse-cases\u002Fconstruction-mining\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002Fstatyba-ir-kalnakasyba\u002F:slug","es":"\u002Fuse-cases\u002Fconstruction-mining\u002F:slug","pt":"\u002Fuse-cases\u002Fconstruction-mining\u002F:slug"},"page_id":149},
    name: "use-cases-construction-mining-:slug-149-slug___pt"
  }, {
    path: "/pt/use-cases/driver-safety/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fdriver-safety\u002F:slug","ru":"\u002Fuse-cases\u002Fdriver-safety\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002Fvairuotoju-sauga\u002F:slug","es":"\u002Fuse-cases\u002Fdriver-safety\u002F:slug","pt":"\u002Fuse-cases\u002Fdriver-safety\u002F:slug"},"page_id":194},
    name: "use-cases-driver-safety-:slug-194-slug___pt"
  }, {
    path: "/pt/use-cases/emobility-management/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Femobility-management\u002F:slug","ru":"\u002Fuse-cases\u002Femobility-management\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002Femobilumas\u002F:slug","es":"\u002Fuse-cases\u002Femobility-management\u002F:slug","pt":"\u002Fuse-cases\u002Femobility-management\u002F:slug"},"page_id":190},
    name: "use-cases-emobility-management-:slug-190-slug___pt"
  }, {
    path: "/pt/use-cases/logistics/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Flogistics\u002F:slug","ru":"\u002Fuse-cases\u002Flogistics\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002Flogistika\u002F:slug","es":"\u002Fuse-cases\u002Flogistics\u002F:slug","pt":"\u002Fuse-cases\u002Flogistics\u002F:slug"},"page_id":150},
    name: "use-cases-logistics-:slug-150-slug___pt"
  }, {
    path: "/pt/use-cases/telematics/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Ftelematics\u002F:slug","ru":"\u002Fuse-cases\u002Ftelematics\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002Ftelematika\u002F:slug","es":"\u002Fuse-cases\u002Ftelematics\u002F:slug","pt":"\u002Fuse-cases\u002Ftelematics\u002F:slug"},"page_id":144},
    name: "use-cases-telematics-:slug-144-slug___pt"
  }, {
    path: "/pt/use-cases/utility-services/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Futility-services\u002F:slug","ru":"\u002Fuse-cases\u002Futility-services\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002Fkomunalines-paslaugos\u002F:slug","es":"\u002Fuse-cases\u002Futility-services\u002F:slug","pt":"\u002Fuse-cases\u002Futility-services\u002F:slug"},"page_id":145},
    name: "use-cases-utility-services-:slug-145-slug___pt"
  }, {
    path: "/ru/about-us/policies-certificates/:slug",
    component: _8d5b5624,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","ru":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai\u002F:slug","es":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","pt":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug"},"page_id":18},
    name: "about-us-policies-certificates-:slug-18-slug___ru"
  }, {
    path: "/ru/products/accessories/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002F:slug","ru":"\u002Fproducts\u002Faccessories\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002F:slug","pt":"\u002Fproducts\u002Faccessories\u002F:slug"},"page_id":139},
    name: "products-accessories-:slug-139-slug___ru"
  }, {
    path: "/ru/products/solutions/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Fsolutions\u002F:slug","ru":"\u002Fproducts\u002Fsolutions\u002F:slug","lt":"\u002Fproduktai\u002Fsprendimai\u002F:slug","es":"\u002Fproducts\u002Fsolutions\u002F:slug","pt":"\u002Fproducts\u002Fsolutions\u002F:slug"},"page_id":137},
    name: "products-solutions-:slug-137-slug___ru"
  }, {
    path: "/ru/products/trackers/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002F:slug"},"page_id":135},
    name: "products-trackers-:slug-135-slug___ru"
  }, {
    path: "/ru/resources/videos/:slug",
    component: _0c947502,
    meta: {"paths":{"en":"\u002Fresources\u002Fvideos\u002F:slug","ru":"\u002Fresources\u002Fvideos\u002F:slug","lt":"\u002Fresursai\u002Fvaizdo-irasai\u002F:slug","es":"\u002Fresources\u002Fvideos\u002F:slug","pt":"\u002Fresources\u002Fvideos\u002F:slug"},"page_id":155},
    name: "resources-videos-:slug-155-slug___ru"
  }, {
    path: "/ru/resources/webinars/:slug",
    component: _2c6c8c4b,
    meta: {"paths":{"en":"\u002Fresources\u002Fwebinars\u002F:slug","ru":"\u002Fresources\u002Fwebinars\u002F:slug","lt":"\u002Fresursai\u002Fwebinarai\u002F:slug","es":"\u002Fresources\u002Fwebinars\u002F:slug","pt":"\u002Fresources\u002Fwebinars\u002F:slug"},"page_id":100},
    name: "resources-webinars-:slug-100-slug___ru"
  }, {
    path: "/ru/use-cases/agriculture/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fagriculture\u002F:slug","ru":"\u002Fuse-cases\u002Fagriculture\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002Fzemes-ukis\u002F:slug","es":"\u002Fuse-cases\u002Fagriculture\u002F:slug","pt":"\u002Fuse-cases\u002Fagriculture\u002F:slug"},"page_id":146},
    name: "use-cases-agriculture-:slug-146-slug___ru"
  }, {
    path: "/ru/use-cases/assets-and-workforce/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fassets-and-workforce\u002F:slug","ru":"\u002Fuse-cases\u002Fassets-and-workforce\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002Fobjektai-ir-personalas-1\u002F:slug","es":"\u002Fuse-cases\u002Fassets-and-workforce\u002F:slug","pt":"\u002Fuse-cases\u002Fassets-and-workforce\u002F:slug"},"page_id":193},
    name: "use-cases-assets-and-workforce-:slug-193-slug___ru"
  }, {
    path: "/ru/use-cases/car-sharing-rental/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fcar-sharing-rental\u002F:slug","ru":"\u002Fuse-cases\u002Fcar-sharing-rental\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002Fautomobiliu-nuoma-ir-dalijimasis\u002F:slug","es":"\u002Fuse-cases\u002Fcar-sharing-rental\u002F:slug","pt":"\u002Fuse-cases\u002Fcar-sharing-rental\u002F:slug"},"page_id":147},
    name: "use-cases-car-sharing-rental-:slug-147-slug___ru"
  }, {
    path: "/ru/use-cases/construction-mining/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fconstruction-mining\u002F:slug","ru":"\u002Fuse-cases\u002Fconstruction-mining\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002Fstatyba-ir-kalnakasyba\u002F:slug","es":"\u002Fuse-cases\u002Fconstruction-mining\u002F:slug","pt":"\u002Fuse-cases\u002Fconstruction-mining\u002F:slug"},"page_id":149},
    name: "use-cases-construction-mining-:slug-149-slug___ru"
  }, {
    path: "/ru/use-cases/driver-safety/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fdriver-safety\u002F:slug","ru":"\u002Fuse-cases\u002Fdriver-safety\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002Fvairuotoju-sauga\u002F:slug","es":"\u002Fuse-cases\u002Fdriver-safety\u002F:slug","pt":"\u002Fuse-cases\u002Fdriver-safety\u002F:slug"},"page_id":194},
    name: "use-cases-driver-safety-:slug-194-slug___ru"
  }, {
    path: "/ru/use-cases/emobility-management/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Femobility-management\u002F:slug","ru":"\u002Fuse-cases\u002Femobility-management\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002Femobilumas\u002F:slug","es":"\u002Fuse-cases\u002Femobility-management\u002F:slug","pt":"\u002Fuse-cases\u002Femobility-management\u002F:slug"},"page_id":190},
    name: "use-cases-emobility-management-:slug-190-slug___ru"
  }, {
    path: "/ru/use-cases/logistics/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Flogistics\u002F:slug","ru":"\u002Fuse-cases\u002Flogistics\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002Flogistika\u002F:slug","es":"\u002Fuse-cases\u002Flogistics\u002F:slug","pt":"\u002Fuse-cases\u002Flogistics\u002F:slug"},"page_id":150},
    name: "use-cases-logistics-:slug-150-slug___ru"
  }, {
    path: "/ru/use-cases/telematics/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Ftelematics\u002F:slug","ru":"\u002Fuse-cases\u002Ftelematics\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002Ftelematika\u002F:slug","es":"\u002Fuse-cases\u002Ftelematics\u002F:slug","pt":"\u002Fuse-cases\u002Ftelematics\u002F:slug"},"page_id":144},
    name: "use-cases-telematics-:slug-144-slug___ru"
  }, {
    path: "/ru/use-cases/utility-services/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Futility-services\u002F:slug","ru":"\u002Fuse-cases\u002Futility-services\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002Fkomunalines-paslaugos\u002F:slug","es":"\u002Fuse-cases\u002Futility-services\u002F:slug","pt":"\u002Fuse-cases\u002Futility-services\u002F:slug"},"page_id":145},
    name: "use-cases-utility-services-:slug-145-slug___ru"
  }, {
    path: "/about-us/policies-certificates/:slug",
    component: _8d5b5624,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","ru":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai\u002F:slug","es":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","pt":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug"},"page_id":18},
    name: "about-us-policies-certificates-:slug-18-slug___en"
  }, {
    path: "/es/events/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fevents\u002F:slug","ru":"\u002Fevents\u002F:slug","lt":"\u002Fevents\u002F:slug","es":"\u002Fevents\u002F:slug","pt":"\u002Fevents\u002F:slug"},"page_id":208},
    name: "events-:slug-208-slug___es"
  }, {
    path: "/es/newsroom/:slug",
    component: _7248b9d5,
    meta: {"paths":{"en":"\u002Fnewsroom\u002F:slug","ru":"\u002Fnewsroom\u002F:slug","lt":"\u002Fnaujienos\u002F:slug","es":"\u002Fnewsroom\u002F:slug","pt":"\u002Fnewsroom\u002F:slug"},"page_id":8},
    name: "newsroom-:slug-8-slug___es"
  }, {
    path: "/es/use-cases/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002F:slug","ru":"\u002Fuse-cases\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002F:slug","es":"\u002Fuse-cases\u002F:slug","pt":"\u002Fuse-cases\u002F:slug"},"page_id":176},
    name: "use-cases-:slug-176-slug___es"
  }, {
    path: "/lt/events/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fevents\u002F:slug","ru":"\u002Fevents\u002F:slug","lt":"\u002Fevents\u002F:slug","es":"\u002Fevents\u002F:slug","pt":"\u002Fevents\u002F:slug"},"page_id":208},
    name: "events-:slug-208-slug___lt"
  }, {
    path: "/lt/naujienos/:slug",
    component: _7248b9d5,
    meta: {"paths":{"en":"\u002Fnewsroom\u002F:slug","ru":"\u002Fnewsroom\u002F:slug","lt":"\u002Fnaujienos\u002F:slug","es":"\u002Fnewsroom\u002F:slug","pt":"\u002Fnewsroom\u002F:slug"},"page_id":8},
    name: "newsroom-:slug-8-slug___lt"
  }, {
    path: "/lt/pritaikymo-sritys/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002F:slug","ru":"\u002Fuse-cases\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002F:slug","es":"\u002Fuse-cases\u002F:slug","pt":"\u002Fuse-cases\u002F:slug"},"page_id":176},
    name: "use-cases-:slug-176-slug___lt"
  }, {
    path: "/products/accessories/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002F:slug","ru":"\u002Fproducts\u002Faccessories\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002F:slug","pt":"\u002Fproducts\u002Faccessories\u002F:slug"},"page_id":139},
    name: "products-accessories-:slug-139-slug___en"
  }, {
    path: "/products/solutions/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Fsolutions\u002F:slug","ru":"\u002Fproducts\u002Fsolutions\u002F:slug","lt":"\u002Fproduktai\u002Fsprendimai\u002F:slug","es":"\u002Fproducts\u002Fsolutions\u002F:slug","pt":"\u002Fproducts\u002Fsolutions\u002F:slug"},"page_id":137},
    name: "products-solutions-:slug-137-slug___en"
  }, {
    path: "/products/trackers/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fproducts\u002Ftrackers\u002F:slug","ru":"\u002Fproducts\u002Ftrackers\u002F:slug","lt":"\u002Fproduktai\u002Fsekimo-iranga\u002F:slug","es":"\u002Fproducts\u002Ftrackers\u002F:slug","pt":"\u002Fproducts\u002Ftrackers\u002F:slug"},"page_id":135},
    name: "products-trackers-:slug-135-slug___en"
  }, {
    path: "/pt/events/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fevents\u002F:slug","ru":"\u002Fevents\u002F:slug","lt":"\u002Fevents\u002F:slug","es":"\u002Fevents\u002F:slug","pt":"\u002Fevents\u002F:slug"},"page_id":208},
    name: "events-:slug-208-slug___pt"
  }, {
    path: "/pt/newsroom/:slug",
    component: _7248b9d5,
    meta: {"paths":{"en":"\u002Fnewsroom\u002F:slug","ru":"\u002Fnewsroom\u002F:slug","lt":"\u002Fnaujienos\u002F:slug","es":"\u002Fnewsroom\u002F:slug","pt":"\u002Fnewsroom\u002F:slug"},"page_id":8},
    name: "newsroom-:slug-8-slug___pt"
  }, {
    path: "/pt/use-cases/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002F:slug","ru":"\u002Fuse-cases\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002F:slug","es":"\u002Fuse-cases\u002F:slug","pt":"\u002Fuse-cases\u002F:slug"},"page_id":176},
    name: "use-cases-:slug-176-slug___pt"
  }, {
    path: "/resources/videos/:slug",
    component: _0c947502,
    meta: {"paths":{"en":"\u002Fresources\u002Fvideos\u002F:slug","ru":"\u002Fresources\u002Fvideos\u002F:slug","lt":"\u002Fresursai\u002Fvaizdo-irasai\u002F:slug","es":"\u002Fresources\u002Fvideos\u002F:slug","pt":"\u002Fresources\u002Fvideos\u002F:slug"},"page_id":155},
    name: "resources-videos-:slug-155-slug___en"
  }, {
    path: "/resources/webinars/:slug",
    component: _2c6c8c4b,
    meta: {"paths":{"en":"\u002Fresources\u002Fwebinars\u002F:slug","ru":"\u002Fresources\u002Fwebinars\u002F:slug","lt":"\u002Fresursai\u002Fwebinarai\u002F:slug","es":"\u002Fresources\u002Fwebinars\u002F:slug","pt":"\u002Fresources\u002Fwebinars\u002F:slug"},"page_id":100},
    name: "resources-webinars-:slug-100-slug___en"
  }, {
    path: "/ru/events/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fevents\u002F:slug","ru":"\u002Fevents\u002F:slug","lt":"\u002Fevents\u002F:slug","es":"\u002Fevents\u002F:slug","pt":"\u002Fevents\u002F:slug"},"page_id":208},
    name: "events-:slug-208-slug___ru"
  }, {
    path: "/ru/newsroom/:slug",
    component: _7248b9d5,
    meta: {"paths":{"en":"\u002Fnewsroom\u002F:slug","ru":"\u002Fnewsroom\u002F:slug","lt":"\u002Fnaujienos\u002F:slug","es":"\u002Fnewsroom\u002F:slug","pt":"\u002Fnewsroom\u002F:slug"},"page_id":8},
    name: "newsroom-:slug-8-slug___ru"
  }, {
    path: "/ru/use-cases/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002F:slug","ru":"\u002Fuse-cases\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002F:slug","es":"\u002Fuse-cases\u002F:slug","pt":"\u002Fuse-cases\u002F:slug"},"page_id":176},
    name: "use-cases-:slug-176-slug___ru"
  }, {
    path: "/use-cases/agriculture/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fagriculture\u002F:slug","ru":"\u002Fuse-cases\u002Fagriculture\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002Fzemes-ukis\u002F:slug","es":"\u002Fuse-cases\u002Fagriculture\u002F:slug","pt":"\u002Fuse-cases\u002Fagriculture\u002F:slug"},"page_id":146},
    name: "use-cases-agriculture-:slug-146-slug___en"
  }, {
    path: "/use-cases/assets-and-workforce/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fassets-and-workforce\u002F:slug","ru":"\u002Fuse-cases\u002Fassets-and-workforce\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002Fobjektai-ir-personalas-1\u002F:slug","es":"\u002Fuse-cases\u002Fassets-and-workforce\u002F:slug","pt":"\u002Fuse-cases\u002Fassets-and-workforce\u002F:slug"},"page_id":193},
    name: "use-cases-assets-and-workforce-:slug-193-slug___en"
  }, {
    path: "/use-cases/car-sharing-rental/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fcar-sharing-rental\u002F:slug","ru":"\u002Fuse-cases\u002Fcar-sharing-rental\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002Fautomobiliu-nuoma-ir-dalijimasis\u002F:slug","es":"\u002Fuse-cases\u002Fcar-sharing-rental\u002F:slug","pt":"\u002Fuse-cases\u002Fcar-sharing-rental\u002F:slug"},"page_id":147},
    name: "use-cases-car-sharing-rental-:slug-147-slug___en"
  }, {
    path: "/use-cases/construction-mining/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fconstruction-mining\u002F:slug","ru":"\u002Fuse-cases\u002Fconstruction-mining\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002Fstatyba-ir-kalnakasyba\u002F:slug","es":"\u002Fuse-cases\u002Fconstruction-mining\u002F:slug","pt":"\u002Fuse-cases\u002Fconstruction-mining\u002F:slug"},"page_id":149},
    name: "use-cases-construction-mining-:slug-149-slug___en"
  }, {
    path: "/use-cases/driver-safety/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fdriver-safety\u002F:slug","ru":"\u002Fuse-cases\u002Fdriver-safety\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002Fvairuotoju-sauga\u002F:slug","es":"\u002Fuse-cases\u002Fdriver-safety\u002F:slug","pt":"\u002Fuse-cases\u002Fdriver-safety\u002F:slug"},"page_id":194},
    name: "use-cases-driver-safety-:slug-194-slug___en"
  }, {
    path: "/use-cases/emobility-management/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Femobility-management\u002F:slug","ru":"\u002Fuse-cases\u002Femobility-management\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002Femobilumas\u002F:slug","es":"\u002Fuse-cases\u002Femobility-management\u002F:slug","pt":"\u002Fuse-cases\u002Femobility-management\u002F:slug"},"page_id":190},
    name: "use-cases-emobility-management-:slug-190-slug___en"
  }, {
    path: "/use-cases/logistics/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Flogistics\u002F:slug","ru":"\u002Fuse-cases\u002Flogistics\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002Flogistika\u002F:slug","es":"\u002Fuse-cases\u002Flogistics\u002F:slug","pt":"\u002Fuse-cases\u002Flogistics\u002F:slug"},"page_id":150},
    name: "use-cases-logistics-:slug-150-slug___en"
  }, {
    path: "/use-cases/telematics/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Ftelematics\u002F:slug","ru":"\u002Fuse-cases\u002Ftelematics\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002Ftelematika\u002F:slug","es":"\u002Fuse-cases\u002Ftelematics\u002F:slug","pt":"\u002Fuse-cases\u002Ftelematics\u002F:slug"},"page_id":144},
    name: "use-cases-telematics-:slug-144-slug___en"
  }, {
    path: "/use-cases/utility-services/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Futility-services\u002F:slug","ru":"\u002Fuse-cases\u002Futility-services\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002Fkomunalines-paslaugos\u002F:slug","es":"\u002Fuse-cases\u002Futility-services\u002F:slug","pt":"\u002Fuse-cases\u002Futility-services\u002F:slug"},"page_id":145},
    name: "use-cases-utility-services-:slug-145-slug___en"
  }, {
    path: "/events/:slug",
    component: _19c6a9a9,
    meta: {"paths":{"en":"\u002Fevents\u002F:slug","ru":"\u002Fevents\u002F:slug","lt":"\u002Fevents\u002F:slug","es":"\u002Fevents\u002F:slug","pt":"\u002Fevents\u002F:slug"},"page_id":208},
    name: "events-:slug-208-slug___en"
  }, {
    path: "/newsroom/:slug",
    component: _7248b9d5,
    meta: {"paths":{"en":"\u002Fnewsroom\u002F:slug","ru":"\u002Fnewsroom\u002F:slug","lt":"\u002Fnaujienos\u002F:slug","es":"\u002Fnewsroom\u002F:slug","pt":"\u002Fnewsroom\u002F:slug"},"page_id":8},
    name: "newsroom-:slug-8-slug___en"
  }, {
    path: "/use-cases/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002F:slug","ru":"\u002Fuse-cases\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002F:slug","es":"\u002Fuse-cases\u002F:slug","pt":"\u002Fuse-cases\u002F:slug"},"page_id":176},
    name: "use-cases-:slug-176-slug___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
